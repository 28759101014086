import type { AsyncData } from "#app/composables/asyncData";
import { DocumentsApi } from "@simbelapp/order-sdk";
import { useSDKApi } from "../api/useSDKApi";
import { useFetchApi } from "~/composables/api/useFetchApi";
import type { DownloadBatchDocumentsPayload } from "~/utils/interfaces/documents-interfaces";

export interface UploadFile {
  file: File;
  id: string;
  isGoodSize: boolean;
  itemId: string;
  itemType: string;
  documentType?: string;
  status: boolean;
}

export const useUploadApi = () => {
  async function uploadFile(file: UploadFile, itemType?: string): Promise<AsyncData<any, any>> {
    if (itemType) {
      file.itemType = itemType;
    }
    const formData = new FormData();
    formData.append("file", file.file);
    formData.append("document_type", file.documentType ? file.documentType : "other");

    formData.append("item_type", file.itemType ? file.itemType : "session");
    formData.append("item_id", file.itemId);
    const apiInstance = await useSDKApi("orders", DocumentsApi);

    const response = await apiInstance.documentsControllerUploadDocumentRaw({
      file: file.file,
      documentType: file.documentType ? file.documentType : ("other" as any),
      itemType: file.itemType ? file.itemType : ("session" as any),
      itemId: file.itemId,
    });

    return response.raw.json();
  }

  function uploadFiles(files: UploadFile[], itemType?: string) {
    return Promise.all(files.map((file: UploadFile) => uploadFile(file, itemType)));
  }

  async function fetchDocuments(item_id: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/documents/findDocuments/${item_id}`, {
      method: "GET",
    });
  }

  async function downloadBatchDocuments(body: DownloadBatchDocumentsPayload) {
    return await useFetchApi("orders", `/documents/downloadBatchDocuments`, {
      method: "POST",
      body,
    });
  }

  /**
   * Return all document for a session whatever the document type
   *
   * @param session_id
   * @returns
   */
  async function fetchSessionDocuments(session_id: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/documents/sessionDocuments/${session_id}`, {
      method: "GET",
    });
  }

  async function downloadDocument(document_id: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/documents/downloadDocument/${document_id}`, {
      method: "GET",
    });
  }

  async function removeDocument(document_id: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/documents/removeDocument/${document_id}`, {
      method: "DELETE",
    });
  }

  return {
    downloadBatchDocuments,
    uploadFile,
    uploadFiles,
    fetchDocuments,
    fetchSessionDocuments,
    downloadDocument,
    removeDocument,
  };
};
