// export enum InvoiceStatusEnum {
//   "NOT_BILLED" = "not_billed",
//   "BILLED" = "billed",
//   "TO_BE_BILLED" = "to_be_billed",
//   "TO_BILLED" = "to_billed",
//   "PARTIAL" = "partial",
//   "EMIT" = "emit",
// }

export enum InvoiceStatusEnum {
  DRAFT = "draft",
  EMITTED = "emitted",
  DISPUTED = "disputed",
  CANCELED = "canceled",
  TO_PAY = "to_pay",
  WAITING_INVOICE_FILE = "waiting_invoice_file",
  WAITING_SIMBEL_PAYMENT = "waiting_simbel_payment",
  NOT_HANDLED_BY_SIMBEL = "not_handled_by_simbel",
  PAID = "paid",
  PARTIALLY_PAID = "partially_paid",
}

export enum BillingsStatusEnum {
  "DIRECT" = "direct",
  "INDIRECT" = "indirect",
}
