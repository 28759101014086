import { type AsyncData } from "#app/composables/asyncData";
import { useFetchApi } from "~/composables/api/useFetchApi";

export const useSessionItemApi = () => {
  async function updateSessionItem(sessionItemId: string, body: Record<any, any>) {
    return await useFetchApi("trainings", `/sessionItems/${sessionItemId}`, {
      method: "PATCH",
      body: { ...body },
    });
  }
  async function disableSessionItem(sessionItemId: string) {
    return await useFetchApi("trainings", `/sessionItems/disable`, {
      method: "PATCH",
      body: {
        id: sessionItemId,
      },
    });
  }
  async function createSessionItem(body: Record<any, any>) {
    return await useFetchApi("trainings", `/sessionItems`, {
      method: "POST",
      body: { ...body },
    });
  }
  async function fetchAllSessionsItemByTrainingId(trainingId: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("trainings", `/sessionItems/training/${trainingId}`, {
      method: "GET",
    });
  }

  return {
    updateSessionItem,
    disableSessionItem,
    fetchAllSessionsItemByTrainingId,
    createSessionItem,
  };
};
