import useVuelidate from "@vuelidate/core";
import { storeToRefs } from "pinia";
import { helpers, required } from "@vuelidate/validators";
import { isBefore, add } from "date-fns";
import { SettingScopesEnum } from "@simbelapp/auth-sdk";
import type { IIndexHeader } from "~/utils/interfaces/table-interfaces";
import { HabilitationStatusEnum } from "~~/utils/enums/habilitations.enums";
import { useCreateUserHabilitationsStore } from "~~/store/users-habilitations/create-user-habilitations.store";
import { useUpdateUserHabilitationsStore } from "~~/store/users-habilitations/update-user-habilitation.store";
import type { IFooterActions } from "~~/utils/interfaces/common-interfaces";
import { ProjectTypeEnum } from "~~/utils/enums/project.enums";
import type { ICompanyUsers } from "~~/utils/interfaces/users-interfaces";
import { useUsersHabilitationsStore } from "~~/store/users-habilitations/users-habilitations.store";
import { useCreateProjectCoreStore } from "~/store/project/create-project-core.store";
import { ItemType } from "~/utils/enums/common.enums";

export const useUsersHabilitations = () => {
  const headers = useState<IIndexHeader[]>(() => [
    {
      name: "Employé",
      sort: true,
      key: "lastname",
      minWidthPx: 246,
      maxWidthFr: 2,
      visible: true,
      scope: SettingScopesEnum.HabilitationsTab,
    },
    {
      name: "Habilitation",
      sort: true,
      key: "habilitation_name",
      minWidthPx: 297,
      maxWidthFr: 5,
      visible: true,
      scope: SettingScopesEnum.HabilitationsTab,
    },
    {
      name: "Validité",
      sort: true,
      key: "habilitation_status",
      minWidthPx: 160,
      maxWidthFr: 2,
      visible: true,
      scope: SettingScopesEnum.HabilitationsTab,
    },
    {
      name: "Expire le",
      sort: true,
      key: "expiration_date",
      minWidthPx: 110,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.HabilitationsTab,
    },
    {
      name: "Certificat",
      sort: true,
      key: "document_link",
      minWidthPx: 123,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.HabilitationsTab,
    },
    {
      name: "À renouveler",
      sort: true,
      key: "to_renew",
      minWidthPx: 117,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.HabilitationsTab,
    },
    {
      name: "Prévu le",
      sort: true,
      key: "renewal_date",
      minWidthPx: 141,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.HabilitationsTab,
    },
    {
      name: "Site",
      sort: true,
      key: "site",
      minWidthPx: 120,
      maxWidthFr: 1,
      visible: false,
      scope: SettingScopesEnum.HabilitationsTab,
    },
    {
      name: "Statut utilisateur",
      sort: true,
      key: "user_status",
      minWidthPx: 150,
      maxWidthFr: 2,
      visible: false,
      scope: SettingScopesEnum.HabilitationsTab,
    },
  ]);

  const footersActions: IFooterActions[] = [
    {
      name: "Ajouter au plan",
      logoName: "create",
      action: showUserManagement,
    },
  ];

  async function showUserManagement() {
    const createProjectCoreStore = useCreateProjectCoreStore();
    const { project } = storeToRefs(createProjectCoreStore);
    const usersHabilitationsStore = useUsersHabilitationsStore();

    createProjectCoreStore.openProjectModal();
    project.value.project_type = ProjectTypeEnum.NEW_PROJECT;
    project.value.source_type = ItemType.INVITE;

    if (
      usersHabilitationsStore.excluded_users_id_list.length > 0 ||
      !usersHabilitationsStore.selected_users_id_list.length
    )
      await usersHabilitationsStore.fetchNotExcludedUserHabilitation(usersHabilitationsStore.excluded_users_id_list);
    const uniqueUsersMap = new Map();
    const selected_items = usersHabilitationsStore.selected_users_id_list.filter((item: ICompanyUsers) => {
      item.display_value = item.display_value ?? item.user_display_value;
      if (!uniqueUsersMap.has(item.user_id)) {
        uniqueUsersMap.set(item.user_id, true);
        return true;
      }
      return false;
    });

    project.value.users.users_id = selected_items.map((u) => {
      return {
        user_id: u.user_id,
        id: project.value.project_id,
        firstname: u.firstname ?? null,
        lastname: u.lastname ?? null,
        email: u.email ?? null,
        display_value: u.display_value ?? null,
      };
    });
  }

  function statusMapping(value: string): { name: string; value: string; type: string } | undefined {
    return [
      {
        name: HabilitationStatusEnum.EXPIRED,
        value: "Expirée",
        type: "light_red",
        logo: "warning_error",
      },
      {
        name: HabilitationStatusEnum.UNKNOWN,
        value: "Non connue",
        type: "grey",
        logo: "warning_error",
      },
      {
        name: HabilitationStatusEnum.VALID,
        value: "Valide",
        type: "light_green",
        logo: "complete",
      },
      {
        name: HabilitationStatusEnum.EXPIRING_SOON,
        value: "Expire bientôt",
        type: "light_orange",
        logo: "duration",
      },
      {
        name: HabilitationStatusEnum.COMING,
        value: "À venir",
        type: "light_blue",
        logo: "time_normal",
      },
    ].find((x) => x.name === value);
  }

  function getHabilitationStatus(expirationDate: Date) {
    if (!expirationDate) {
      return HabilitationStatusEnum.COMING;
    } else if (isBefore(expirationDate, new Date())) {
      return HabilitationStatusEnum.EXPIRED;
    } else if (isBefore(expirationDate, add(new Date(), { months: 6 }))) {
      return HabilitationStatusEnum.EXPIRING_SOON;
    } else {
      return HabilitationStatusEnum.VALID;
    }
  }

  return {
    headers,
    footersActions,
    statusMapping,
    getHabilitationStatus,
  };
};

export const useUsersHabilitationsValidation = () => {
  async function validateUsersHabilitationsCreation() {
    const createUserHabilitationStore = useCreateUserHabilitationsStore();
    const { user } = storeToRefs(createUserHabilitationStore);

    const mandatoryFieldMessage = "Ce champ est obligatoire";

    const rules = computed(() => {
      return {
        habilitation_name: {
          required: helpers.withMessage(mandatoryFieldMessage, required),
        },
        duration_years: {
          required: helpers.withMessage(mandatoryFieldMessage, required),
        },
        user_id: {
          required: helpers.withMessage(mandatoryFieldMessage, required),
        },
      };
    });
    const v$ = useVuelidate(rules, user);
    await v$.value.$validate();

    return v$;
  }

  async function validateUsersHabilitationsUpdate() {
    const updateUserHabilitationStore = useUpdateUserHabilitationsStore();
    const { userUpdate } = storeToRefs(updateUserHabilitationStore);

    const mandatoryFieldMessage = "Ce champ est obligatoire";

    const rules = computed(() => {
      return {
        habilitation_name: {
          required: helpers.withMessage(mandatoryFieldMessage, required),
        },
        duration_years: {
          required: helpers.withMessage(mandatoryFieldMessage, required),
        },
        user_id: {
          required: helpers.withMessage(mandatoryFieldMessage, required),
        },
      };
    });

    const v$ = useVuelidate(rules, userUpdate);
    await v$.value.$validate();

    return v$;
  }

  return {
    validateUsersHabilitationsCreation,
    validateUsersHabilitationsUpdate,
  };
};
