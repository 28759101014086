export enum AttendeesConfirmEnum {
  CONFIRMED = "confirmed",
  TRAINING_STARTED = "training_started",
  TRAINING_ENDED = "training_ended",
  REGISTERED = "registered",
  TO_COMPLETE = "to_complete",
  TO_VALIDATE = "to_validate",
}

export enum AttendeesPendingEnum {
  PROPOSAL_PENDING = "proposal_pending",
  PENDING = "pending",
  TO_BE_CONFIRMED = "to_be_confirmed",
  PENDING_EMPLOYEE = "pending_employee",
}

export enum SessionStatusEnum {
  PENDING_INVITATION = "pending_invitation",
  ENDED = "ended",
  UPCOMING = "upcoming",
  ONGOING = "ongoing",
  TO_COMPLETE = "to_complete",
  TO_VALIDATE = "to_validate",
  COMPLETED = "completed",
  FINISHED = "finished",
  NO_SHOW = "no_show",
}

export enum SessionDetailsEndNavEnum {
  SESSION,
  SATISFACTION,
}

export enum SessionDetailsSatisfactionTypeEnum {
  DIRECT = "direct_pedagogical_report",
  DELAYED = "delayed_pedagogical_report",
}
