export enum ProjectNavEnum {
  TRAINING,
  ATTENDEES,
  SESSION,
  FINANCING,
}

export enum ProjectNavRegulationEnum {
  TRAINING,
  SESSION_ITEM,
  SESSION,
  ATTENDEES,
  FINANCING,
}

export enum ProjectTypeEnum {
  NEW_PROJECT = "new-project",
  REGULATION = "regulation",
}
