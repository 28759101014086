export enum BookingEventsEnum {
  BOOKING_SUBMITTED = "booking_submitted",
  BOOKING_CONFIRMED = "booking_confirmed",
  BOOKING_VALIDATED = "booking_validated",
  BOOKING_CANCELED = "booking_canceled",
  BOOKING_INCOMPLETE = "booking_incomplete",
  BOOKING_ENDED = "booking_ended",
  BOOKING_NO_SHOW = "booking_no_show",
  BOOKING_TO_BE_CONFIRMED = "booking_to_be_confirmed",
  BOOKING_DATES_UPDATED = "booking_dates_updated",
  BOOKING_NOTIFICATION = "booking_notification",
  CPF_BOOKING_TO_CONFIRM = "cpf_booking_to_confirm",
  DELAYED_PEDAGOGICAL_REPORT = "delayed_pedagogical_report",
  DIRECT_PEDAGOGICAL_REPORT = "direct_pedagogical_report",
}

export enum BookingStatusEnum {
  BOOKING_CONFIRMED = "confirmed",
  BOOKING_TO_BE_CONFIRMED = "to_be_confirmed",
  BOOKING_CANCELED = "canceled",
  BOOKING_NO_SHOW = "no_show",
  BOOKING_REFUSED = "refused",
}

export enum BookingICSStatusEnum {
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  CANCELED = "canceled",
}
