import { defineStore } from "pinia";
import type { ISkillsStore } from "~/utils/interfaces/skills-interfaces";
import { useSkillsApi } from "~/composables/skills/useSkillsApi";

export const useSkillsStore = defineStore("skillsStore", {
  state: (): ISkillsStore => {
    return {
      skills: [],
    };
  },
  getters: {},
  actions: {
    async fetchSkills() {
      const skillsApi = useSkillsApi();

      const { data } = await skillsApi.fetchSkills();

      if (data.value) {
        this.skills = data.value;
      }
    },
  },
});
