import { defineStore } from "pinia";
import { useCreateProjectStore } from "../project/create-project.store";
import { useFeedback } from "~/composables/feedback/useFeedback";
import type { ISessionItem, ITraining } from "~~/utils/interfaces/trainings-interface";
import { useSessionItemApi } from "~~/composables/session-item/useSessionItemApi";

export const useSessionItemStore = defineStore("sessionItemStore", {
  getters: {},
  actions: {
    async updateSessionItem(
      training: {
        training_id: ITraining | null;
      },
      session_item: {
        session_item_id: ISessionItem | null;
        session_item_type: string | null;
      },
    ) {
      const feedback = useFeedback();

      try {
        const sessionItem = useSessionItemApi();
        const createProjectStore = useCreateProjectStore();

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { user_id, ...totalBudget } = createProjectStore.getProjectApiPayload?.project_users?.length
          ? createProjectStore.getProjectApiPayload?.project_users[0]
          : { user_id: null };
        const arrayOfBudget = Object.values(totalBudget);
        const initialValue = 0;
        const sumBudget = arrayOfBudget.reduce<number>(
          (accumulator, currentValue) => accumulator + currentValue,
          initialValue,
        );
        if (session_item.session_item_id && training.training_id) {
          await sessionItem.updateSessionItem(session_item.session_item_id.session_item_id as string, {
            training_id: training.training_id?.training_id,
            price_excl_tax: sumBudget ?? session_item.session_item_id?.price_excl_tax,
            type: session_item.session_item_type,
            modality: session_item.session_item_id?.modality,
            format: session_item.session_item_id?.format,
          });

          return await this.findAllSessionItems(training.training_id.training_id);
        }
      } catch (error) {
        feedback.error(`erreur`, "small");
      }
    },
    async updateTrainingSessionItem(sessionItemId: string, sessionItems: ISessionItem, trainingId?: string) {
      const sessionItem = useSessionItemApi();
      const feedback = useFeedback();
      try {
        const { data } = await sessionItem.updateSessionItem(sessionItemId, {
          price_excl_tax: sessionItems.price_excl_tax,
          type: sessionItems.type,
          modality: sessionItems.modality,
          format: sessionItems.format,
          max_nb_attendees: sessionItems.max_nb_attendees,
          min_nb_attendees: sessionItems.min_nb_attendees,
          overbooking_not_allowed: sessionItems.overbooking_not_allowed,
          ...(trainingId && { training_id: trainingId }),
        });

        return data.value;
      } catch (error) {
        feedback.error(`erreur`, "small");
      }
    },
    async disableTrainingSessionItem(sessionItemId: string) {
      const sessionItem = useSessionItemApi();
      const feedback = useFeedback();
      try {
        const { data } = await sessionItem.disableSessionItem(sessionItemId);

        return data.value;
      } catch (error) {
        feedback.error(`erreur`, "small");
      }
    },
    async createSessionItem(sessionItems: ISessionItem, trainingId: string) {
      const sessionItem = useSessionItemApi();
      const feedback = useFeedback();
      try {
        const { data } = await sessionItem.createSessionItem({
          training_id: trainingId,
          price_excl_tax: sessionItems.price_excl_tax,
          type: sessionItems.type,
          modality: sessionItems.modality,
          format: sessionItems.format,
          max_nb_attendees: sessionItems.max_nb_attendees,
          min_nb_attendees: sessionItems.min_nb_attendees,
          overbooking_not_allowed: sessionItems.overbooking_not_allowed,
        });

        return data.value;
      } catch (error) {
        feedback.error(`erreur`, "small");
      }
    },
    async findAllSessionItems(training_id: string) {
      const sessionItem = useSessionItemApi();
      const sessionItems = await sessionItem.fetchAllSessionsItemByTrainingId(training_id);
      return sessionItems.data.value[0];
    },
  },
});
