import {
  InvoicesInvoicesFilesApi,
  InvoicesTeamsApi,
  UpdateInvoiceStatusDtoStatusEnum,
  InvoicesCRUDApi,
} from "@simbelapp/billing-sdk";
import { defineStore } from "pinia";
import { useSDKApi } from "~/composables/api/useSDKApi";
import { useRudderstack } from "~/composables/utils/useRudderstack";
import { useSessionDetailsStore } from "~/store/sessions/session-details-store";
import { InvoiceStatusEnum } from "~/utils/enums/billings.enums";
import { MicroserviceEnum } from "~/utils/enums/common.enums";
import type { IBillingsStore } from "~/utils/interfaces/billings-interfaces";
import { useFeedback } from "~~/composables/feedback/useFeedback";

export const useBillingsStore = defineStore("billingsStore", {
  state: (): IBillingsStore => {
    return {
      session_invoices_list_in: null,
      session_invoices_list_out: null,
      billing_documents_lines_in: null,
      billing_documents_lines_out: null,
    };
  },
  getters: {},
  actions: {
    async getSessionInvoices(sessionId: string, isIn: boolean) {
      const feedback = useFeedback();
      try {
        const apiInstance = await useSDKApi(MicroserviceEnum.BILLING, InvoicesTeamsApi);
        const sessionInvoice = await apiInstance.findInvoiceInBySessionId({
          sessionId,
          isIn: isIn.toString(),
        });

        if (isIn) {
          this.session_invoices_list_in = sessionInvoice;

          this.billing_documents_lines_in = sessionInvoice.map((invoice) => {
            return {
              sessionInvoice: invoice,
              action: {
                editStatus: false,
                uploadFile: false,
                delete: false,
              },
            };
          });
        } else {
          const sessionDetailsStore = useSessionDetailsStore();
          const { session_training, session_details } = sessionDetailsStore;
          this.session_invoices_list_out = sessionInvoice;

          this.billing_documents_lines_out = sessionInvoice.map((invoice) => {
            const canUpload: boolean =
              (session_training?.is_company_training && invoice.status !== InvoiceStatusEnum.PAID) ||
              session_details?.isNotBillable ||
              false;

            return {
              sessionInvoice: invoice,
              action: {
                editStatus: session_details?.isNotBillable || false,
                uploadFile: canUpload,
                delete: session_details?.isNotBillable || false,
              },
            };
          });
        }
      } catch (error) {
        // 404 ok, !404 error
        feedback.error(`Une erreur est survenue`, "small");
      }
    },

    async uploadPartnerInvoice(sessionId: string, file: Blob) {
      const feedback = useFeedback();
      try {
        const apiInstance = await useSDKApi(MicroserviceEnum.BILLING, InvoicesTeamsApi);
        const rudderstack = useRudderstack();
        rudderstack.track("billing_upload_partner_invoice", {
          session_id: sessionId,
        });

        return await apiInstance
          .uploadPartnerInvoiceByCompany({
            sessionId,
            file,
          })
          .catch(async (error) => {
            const errorResponse = await error.response.json();
            if (errorResponse.message.includes("without bucket")) {
              feedback.error(
                "Impossible d'ajouter une facture quand aucun mode de financement n'est sélectionné",
                "small",
              );
            } else if (errorResponse.message.includes("Cannot create invoice item because item status")) {
              feedback.error(
                "Impossible d'ajouter une facture tant qu'aucun participant n'est inscrit sur la session",
                "small",
              );
            } else if (errorResponse.message.includes("Amount excl tax is missing or 0")) {
              feedback.error("Impossible d'ajouter une facture pour une formation à 0€", "small");
            } else {
              feedback.error("Une erreur est survenue", "small");
            }
            throw new Error(`Une erreur est survenue`);
          });
      } catch (error) {
        // feedback.error(`Une erreur est survenue`, "small");
        throw new Error(`Une erreur est survenue`);
      }
    },

    async cancelInvoice(invoiceId: string) {
      const feedback = useFeedback();
      try {
        const apiInstance = await useSDKApi(MicroserviceEnum.BILLING, InvoicesCRUDApi);

        const rudderstack = useRudderstack();
        rudderstack.track("billing_cancel_invoice", {
          invoice_id: invoiceId,
        });

        await apiInstance
          .cancelInvoice({
            invoiceId,
          })
          .catch(async (error) => {
            const errorResponse = await error.response.json();
            feedback.error(errorResponse.message, "small");
          });
        this.session_invoices_list_out =
          this.session_invoices_list_out?.filter((invoice) => invoice.invoiceId !== invoiceId) || [];
        this.billing_documents_lines_out =
          this.billing_documents_lines_out?.filter((bd) => bd.sessionInvoice.invoiceId !== invoiceId) || [];
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },

    async updateInvoiceStatus(invoiceId: string, status: UpdateInvoiceStatusDtoStatusEnum) {
      const feedback = useFeedback();
      try {
        const apiInstance = await useSDKApi(MicroserviceEnum.BILLING, InvoicesTeamsApi);

        const rudderstack = useRudderstack();
        rudderstack.track("billing_update_invoice", {
          invoice_id: invoiceId,
        });

        const sessionInvoice = await apiInstance
          .updateInvoiceStatus({
            updateInvoiceStatusDto: {
              status,
              invoiceId,
            },
          })
          .catch((error) => {
            feedback.error(error.response.json().message, "small");
          });

        if (sessionInvoice) {
          this.session_invoices_list_out?.forEach((invoice) => {
            if (invoice.invoiceId === sessionInvoice.invoiceId) {
              invoice = sessionInvoice;
            }
          });

          this.billing_documents_lines_out?.forEach((bd) => {
            if (bd.sessionInvoice.invoiceId === sessionInvoice.invoiceId) {
              bd.sessionInvoice = sessionInvoice;
            }
          });
        }
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },

    reset() {
      this.session_invoices_list_in = null;
      this.session_invoices_list_out = null;
      this.billing_documents_lines_in = null;
      this.billing_documents_lines_out = null;
    },

    async downloadInvoice(invoiceId: string): Promise<Blob | undefined> {
      const feedback = useFeedback();
      try {
        const apiInstance = await useSDKApi(MicroserviceEnum.BILLING, InvoicesInvoicesFilesApi);

        const rudderstack = useRudderstack();
        rudderstack.track("billing_download_invoice", {
          invoice_id: invoiceId,
        });

        const file = await apiInstance.downloadInvoiceFile({
          invoiceId,
        });

        return file;
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },
    async replaceInvoiceFile(invoiceId: string, file: Blob, sessionId: string) {
      const feedback = useFeedback();
      try {
        const apiInstance = await useSDKApi(MicroserviceEnum.BILLING, InvoicesTeamsApi);

        const rudderstack = useRudderstack();
        rudderstack.track("billing_replace_invoice", {
          invoice_id: invoiceId,
        });

        await apiInstance.replaceInvoiceFile({
          invoiceId,
          file,
        });

        await this.getSessionInvoices(sessionId, false);
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },
  },
});
