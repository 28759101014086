import { defineStore } from "pinia";

import type { ErrorObject } from "@vuelidate/core";
import { ItemType as SdkItemType, ItemType } from "@simbelapp/order-sdk";
import { TagsApi, type TagDetails } from "@simbelapp/order-sdk";
import { useSessionItemStore } from "../session-item/session-item-store";
import { useCatalogStore } from "../catalog/catalog.store";
import { useTrainingPartnersStore } from "../training-partners/training-partners.store";
import { useUsersHabilitationsStore } from "../users-habilitations/users-habilitations.store";
import { useSessionsStore } from "../sessions/sessions-store";
import { useCategoriesStore } from "../categories/categories.store";
import { useTrainingsStore } from "./trainings.store";
import { TagCategoryEnum } from "~/utils/enums/tags.enums";
import { RegistrationType, TrainingNavEnum, TrainingTypeEnum } from "~~/utils/enums/training.enums";
import type {
  ICreateTraining,
  ISessionItemCreateTraining,
  ITrainingSession,
} from "~~/utils/interfaces/trainings-interface";
import { useFeedback } from "~~/composables/feedback/useFeedback";
import { SessionItemModalityEnum, SessionItemTypeEnum } from "~~/utils/enums/session-items.enums";
import { UserLanguageEnum } from "~/utils/enums/users.enums";
import type { ISkills } from "~/utils/interfaces/skills-interfaces";
import { useAuthStore } from "~/store/auth/auth.store";
import { usePagination } from "~/composables/paginations/usePagination";
import { useCatalogApi } from "~~/composables/catalog/useCatalogApi";
import { useTrainingValidation } from "~~/composables/trainings/useTrainings";
import type { ICategory } from "~~/utils/interfaces/categories-interfaces";
import type { ICompanyUsers } from "~~/utils/interfaces/users-interfaces";
import { useFormats } from "~/composables/utils/useFormat";
import { useSDKApi } from "~/composables/api/useSDKApi";
import { MicroserviceEnum } from "~/utils/enums/common.enums";

export const useCreateTrainingStore = defineStore("createTrainingStore", {
  state: (): ICreateTraining => {
    const authStore = useAuthStore();

    return {
      training: {
        general: {
          training_partner: null,
          title: null,
          main_contact_email: null,
          external_url: null,
          internal_url: null,
          is_company_training: false,
          categories: null,
          skills: null,
          duration_hours: null,
          duration_days: null,
          visibility: false,
          type: null,
          training_admins: [],
          training_tags: null,
        },
        content: {
          goals: [],
          target_audience: null,
          program: null,
          language: (authStore?.user?.language?.toLowerCase() as UserLanguageEnum) ?? UserLanguageEnum.FR,
          isHabilitation: false,
          habilitation: null,
          duration_years: null,
        },
        session_items: {
          individual: {
            format: null,
            modality: SessionItemModalityEnum.SPECIFIC_DATES,
            price_excl_tax: 0,
            type: SessionItemTypeEnum.INDIVIDUAL,
            isChecked: false,
            max_nb_attendees: 1,
            min_nb_attendees: 1,
            overbooking_not_allowed: false,
          },
          group: {
            format: null,
            modality: SessionItemModalityEnum.SPECIFIC_DATES,
            price_excl_tax: 0,
            max_nb_attendees: 1,
            min_nb_attendees: 1,
            type: SessionItemTypeEnum.GROUP,
            isChecked: false,
            overbooking_not_allowed: false,
          },
        },
        modalities: {
          no_request: false,
          is_not_billable: false,
          is_cpf_eligible: false,
          is_opco_eligible: false,
          registration_type: RegistrationType.PARTNER_REGISTRATION,
          trainer_cpf_url: null,
        },
        sessions: {
          session_draft: {
            trainingAddress: null,
            sessionItemType: null,
            format: null,
            startDate: null,
            endDate: null,
            designation: null,
            overbookingNotAllowed: false,
            sessionTimeslot: [],
            currentSessionTimeslot: null,
          },
          sessionList: [],
          show_add_session: false,
          showAddTimeslot: false,
          showEditTimeslotModalConfirm: null,
          showDeleteModal: false,
          showWarningDeleteModal: false,
          editTimeslot: null,
        },
        errors: {},
        disabled: {
          main_contact_email: false,
        },
      },
      nav: TrainingNavEnum.GENERAL,
      show_modal: false,
      show_exit: false,
      is_edit: false,
      readonly: false,
    };
  },
  getters: {},
  actions: {
    async openTrainingModal(id?: string) {
      this.$reset();
      const router = useRouter();
      const authStore = useAuthStore();

      if (id) {
        await router.push({ query: { id } });
        await this.loadTraining(id);
        this.is_edit = true;
      }
      if (authStore.user && this.is_edit === false) {
        const u = authStore.user;
        let displayValue;

        if (u.firstname && u.lastname) {
          displayValue = `${u.firstname} ${u.lastname}`;
        } else if (u.email) {
          displayValue = `${u.email}`;
        } else {
          displayValue = u.employee_number ?? "";
        }

        const defaultUser: ICompanyUsers = {
          user_id: u.user_id!,
          email: u.email ?? "",
          firstname: u.firstname ?? "",
          lastname: u.lastname ?? "",
          employee_number: u.employee_number ?? "",
          display_value: displayValue,
        };

        this.training.general.training_admins.push(defaultUser);
      }

      this.show_modal = true;
    },
    resetSessionItem(type: SessionItemTypeEnum) {
      return {
        isChecked: false,
        format: null,
        modality: SessionItemModalityEnum.SPECIFIC_DATES,
        price_excl_tax: 0,
        max_nb_attendees: 1,
        min_nb_attendees: 1,
        type,
        overbooking_not_allowed: false,
      };
    },
    async loadTraining(id: string) {
      const catalogApi = useCatalogApi();
      const formats = useFormats();

      const { data } = await catalogApi.getCompanyTrainingDetails(id);

      if (data.value) {
        this.training = data.value;
        this.training.errors = {};
        this.training.disabled = {};

        const transformedTags = data.value.general.training_tags?.map((tag: any) => ({
          tagFamilyColor: tag.tag_family_color,
          tagFamilyId: tag.tag_family_id,
          tagId: tag.tag_id,
          name: tag.tag_name,
          category: tag.category,
          deleted_at: tag.deleted_at,
        }));
        this.training.general.training_tags = transformedTags;
        // If training is not a company training, it is readonly
        this.readonly = !this.training.general.is_company_training;

        if (this.training.general.main_contact_email) {
          this.training.disabled.main_contact_email = true;
        } else {
          this.training.disabled.main_contact_email = false;
        }

        if (!this.training.session_items.group) {
          this.training.session_items.group = this.resetSessionItem(SessionItemTypeEnum.GROUP);
        } else {
          this.training.session_items.group.isChecked = !!this.training.session_items.group;
        }

        if (!this.training.session_items.individual) {
          this.training.session_items.individual = this.resetSessionItem(SessionItemTypeEnum.INDIVIDUAL);
        } else {
          this.training.session_items.individual.isChecked = !!this.training.session_items.individual;
        }

        if (this.training.general?.duration_hours) {
          this.training.general.duration_hours = formats.formatTo2Decimals(this.training.general.duration_hours);
        }

        this.training.content.isHabilitation = !!this.training.content.habilitation?.habilitation_id;

        this.training.content.duration_years = this.training.content.habilitation?.duration_years as number;

        this.training.sessions = {
          session_draft: null,
          sessionList: [],
          show_add_session: false,
          showAddTimeslot: false,
          showEditTimeslotModalConfirm: null,
          showDeleteModal: false,
          showWarningDeleteModal: false,
          editTimeslot: null,
        };

        await this.loadSessions(id);

        this.training.sessions.sessionList = this.training.sessions.sessionList ?? [];
        this.training.sessions.session_draft = {
          trainingAddress: null,
          sessionItemType: null,
          format: null,
          startDate: null,
          endDate: null,
          designation: null,
          sessionTimeslot: [],
          currentSessionTimeslot: null,
        };
      }
    },

    async loadSessions(trainingId: string) {
      const sessionsStore = useSessionsStore();

      this.training.sessions.sessionList = (await sessionsStore.fetchSessionsByTrainingId(
        trainingId,
        "false",
      )) as ITrainingSession[];
    },

    pushValidationErrors(errors: ErrorObject[]) {
      errors.forEach((err: ErrorObject) => {
        this.training.errors[err.$propertyPath] = err.$message as string;
      });
    },

    selectNewTrainingPartner(partner_name: string) {
      this.training.general.training_partner = {
        training_partner_id: null,
        name: partner_name,
      };
    },

    selectNewCategory(category_name: string) {
      const categoryStore = useCategoriesStore();
      const alreadyExist =
        categoryStore.categories &&
        categoryStore.categories.find((cat) => {
          return cat.name === category_name;
        });
      if (!alreadyExist) {
        const newCategories: ICategory = {
          name: category_name,
          code_name: "🎓",
          is_company_category: true,
          priority: 12,
        };

        this.training.general.categories = newCategories;
        categoryStore.categories && categoryStore.categories.push(newCategories);
      }
    },

    async createSessionItems(
      sessionItems: {
        individual: ISessionItemCreateTraining;
        group: ISessionItemCreateTraining;
      },
      trainingId: string,
    ) {
      const sessionItemStore = useSessionItemStore();

      const promises = [];

      if (sessionItems?.individual?.isChecked && !sessionItems.individual.session_item_id) {
        promises.push(
          sessionItemStore.createSessionItem(
            {
              ...sessionItems.individual,
            },
            trainingId,
          ),
        );
      }

      if (sessionItems?.group?.isChecked && !sessionItems.group.session_item_id) {
        promises.push(
          sessionItemStore.createSessionItem(
            {
              ...sessionItems.group,
            },
            trainingId,
          ),
        );
      }

      return await Promise.all(promises);
    },

    async updateSessionItems(
      sessionItems: {
        individual: ISessionItemCreateTraining;
        group: ISessionItemCreateTraining;
      },
      sessionItemId: string,
    ) {
      const sessionItemStore = useSessionItemStore();

      const promises = [];

      if (sessionItems?.individual?.isChecked) {
        promises.push(
          sessionItemStore.updateTrainingSessionItem(sessionItemId, {
            ...sessionItems.individual,
          }),
        );
      }

      if (sessionItems?.group?.isChecked) {
        promises.push(
          sessionItemStore.updateTrainingSessionItem(sessionItemId, {
            ...sessionItems.group,
          }),
        );
      }

      return await Promise.all(promises);
    },

    async createTrainingPartner() {
      const { general } = this.training;
      const trainingPartnersStore = useTrainingPartnersStore();

      if (!general.training_partner?.training_partner_id && general.training_partner?.name) {
        /** create tp with name */
        const trainingPartner = await trainingPartnersStore.create({
          name: general.training_partner.name,
        });
        general.training_partner.training_partner_id = trainingPartner?.value.training_partner_id;
      }
    },
    async createUpdateCategories() {
      const categoriesStore = useCategoriesStore();
      const catFilters = categoriesStore.categories?.filter((cat) => cat.is_company_category) as ICategory[];
      await categoriesStore.createUpdateCategories(catFilters);
    },
    async updateCategoriesIdSelected() {
      const categoriesStore = useCategoriesStore();
      await categoriesStore.fetchCategories();

      if (categoriesStore.categories) {
        this.training.general.categories = categoriesStore.categories?.find(
          (cat) => this.training.general.categories && cat.name === this.training.general.categories.name,
        ) as ICategory;
      }
    },
    async createHabilitation() {
      const { content } = this.training;
      const userHabilitationsStore = useUsersHabilitationsStore();
      if (content.isHabilitation && content?.habilitation?.name && content.duration_years) {
        /** create or update habilitation */
        const createHabilitation = await userHabilitationsStore.createUpdate({
          name: content.habilitation?.name,
          duration_years: content.duration_years,
        });

        content.habilitation.habilitation_id = createHabilitation?.value.habilitation_id;
      } else if (!content.isHabilitation) {
        /** disable habilitation */
        if (content.habilitation?.habilitation_id) content.habilitation.habilitation_id = null;
      }
    },
    async createUpdateTraining(trainingId?: string) {
      const { general, modalities, content } = this.training;
      const trainingsStore = useTrainingsStore();

      await this.createUpdateCategories();
      await this.updateCategoriesIdSelected();

      const categoriesIds = [general.categories?.category_id];

      return (
        general &&
        content &&
        (await trainingsStore.createOwnTraining({
          training_id: trainingId,
          title: general.title,
          type: general.type,
          partner_id: general.training_partner?.training_partner_id,
          ...(TrainingTypeEnum.EXTERNAL === general.type && {
            external_url: general.external_url,
          }),
          ...(TrainingTypeEnum.INTERNAL === general.type && {
            internal_url: general.internal_url,
          }),
          categories: categoriesIds,
          no_request: modalities.no_request,
          skills: general.skills?.map((s: ISkills) => s.skill_id),
          language: content.language,
          duration_hours: general.duration_hours,
          duration_days: general.duration_days,
          goals: content.goals.filter((goal) => goal !== ""),
          target_audience: content.target_audience,
          program: content.program,
          ...(content.isHabilitation &&
            content.habilitation && {
              mandatory: content.isHabilitation,
              habilitation_id: content.habilitation.habilitation_id,
            }),
          ...(!content.isHabilitation && {
            mandatory: !content.isHabilitation,
            habilitation_id: null,
          }),
          ...(TrainingTypeEnum.EXTERNAL === general.type && modalities),
        }))
      );
    },
    async updateContactEmail() {
      /** change email contact */
      const { general } = this.training;
      const trainingPartnersStore = useTrainingPartnersStore();

      if (general.training_partner?.training_partner_id && general.main_contact_email) {
        const hasEmail = await trainingPartnersStore.findOneTrainingPartner(
          general.training_partner?.training_partner_id,
        );

        if (!hasEmail?.main_contact_email || hasEmail?.main_contact_email !== general.main_contact_email) {
          await trainingPartnersStore.updateMainContact({
            partner_id: general.training_partner.training_partner_id,
            email: general.main_contact_email,
          });
        }
      }
    },
    async changeVisibility(trainingId: string) {
      /**  Visibility from training_id */
      const catalogStore = useCatalogStore();
      const { general } = this.training;

      return (
        general.visibility !== null &&
        general.visibility !== undefined &&
        (await catalogStore.changeVisibility([trainingId], general.visibility))
      );
    },
    async addTraining(company_id: string) {
      const feedback = useFeedback();
      const { session_items } = this.training;

      await this.createTrainingPartner();

      await this.createHabilitation();

      /* CREATE Training **/
      const trainingResult = await this.createUpdateTraining();

      if (trainingResult?.value) {
        /** CREATE SI from training_id */
        const createSessionItemsResult = await this.createSessionItems(session_items, trainingResult.value.training_id);

        /** CREATE TRAINING ADMIN */
        const trainingsStore = useTrainingsStore();
        const adminsIds = this.training.general.training_admins?.length
          ? this.training.general.training_admins.map((admin) => admin.user_id)
          : [];
        await trainingsStore.updateCreateTrainingAdmin(trainingResult.value.training_id, adminsIds);

        await this.changeVisibility(trainingResult.value.training_id);

        if (TrainingTypeEnum.EXTERNAL === this.training.general.type) await this.updateContactEmail();

        if (createSessionItemsResult) {
          feedback.success("La formation a bien été créée", "small");

          this.is_edit = true;
          this.nav = 4;

          const router = useRouter();
          await router.push({
            query: { id: trainingResult.value.training_id },
          });
          if (company_id) {
            await this.assignTagsToItems(
              company_id,
              TagCategoryEnum.TRAINING_TAG,
              ItemType.Training,
              this.training.general.training_tags,
              trainingResult.value.training_id,
            );
          }
          await this.loadTraining(trainingResult.value.training_id);

          const paginate = usePagination();
          await paginate.fetchData();
        } else {
          feedback.error(`Une erreur est survenue`, "small");
        }
      }
    },

    async editTraining() {
      const feedback = useFeedback();

      /** edit Training */
      this.validation();
      const { session_items, sessions, errors } = this.training;
      if (!Object.keys(errors).length) {
        const router = useRouter();
        const trainingId = router.currentRoute.value.query.id;
        if (trainingId) {
          await this.createTrainingPartner();

          await this.createHabilitation();

          /* CREATE Training **/
          const trainingResult = await this.createUpdateTraining(trainingId as string);

          /** UPDATE SI */
          const sessionItemStore = useSessionItemStore();

          if (session_items.group.isChecked) {
            if (session_items.group.session_item_id) {
              await sessionItemStore.updateTrainingSessionItem(
                session_items.group.session_item_id as string,
                {
                  ...session_items.group,
                },
                trainingId as string,
              );
            } else {
              const session = await sessionItemStore.createSessionItem(
                {
                  ...session_items.group,
                },
                trainingId as string,
              );
              session_items.group.session_item_id = session.available;
            }
          } else if (session_items.group.session_item_id) {
            await sessionItemStore.disableTrainingSessionItem(session_items.group.session_item_id as string);
          }

          if (session_items.individual.isChecked) {
            if (session_items.individual.session_item_id) {
              await sessionItemStore.updateTrainingSessionItem(
                session_items.individual.session_item_id as string,
                {
                  ...session_items.individual,
                },
                trainingId as string,
              );
            } else {
              const session = await sessionItemStore.createSessionItem(
                {
                  ...session_items.individual,
                },
                trainingId as string,
              );
              session_items.individual.session_item_id = session.available;
            }
          } else if (session_items.individual.session_item_id) {
            await sessionItemStore.disableTrainingSessionItem(session_items.individual.session_item_id as string);
          }

          await this.updateContactEmail();

          if (sessions.sessionList.length) {
            const sessionStore = useSessionsStore();

            sessions.sessionList.forEach((s) => {
              if (!s.sessionItemId) {
                if (s.sessionItemType === SessionItemTypeEnum.INDIVIDUAL) {
                  s.sessionItemId = session_items.individual.session_item_id as string;
                } else if (s.sessionItemType === SessionItemTypeEnum.GROUP) {
                  s.sessionItemId = session_items.group.session_item_id as string;
                }
              }
            });

            const promises: Promise<void>[] = [];
            sessions.sessionList.forEach((session) => {
              if (session.isNewSession) promises.push(sessionStore.createSession(session));
            });

            await Promise.all(promises);
          }

          await this.changeVisibility(trainingId as string);

          /** CREATE TRAINING ADMIN */
          const trainingsStore = useTrainingsStore();
          const adminsIds = this.training.general.training_admins?.length
            ? this.training.general.training_admins.map((admin) => admin.user_id)
            : [];
          await trainingsStore.updateCreateTrainingAdmin(trainingId as string, adminsIds);

          if (trainingResult?.value) {
            feedback.success("Vos modifications ont bien été sauvegardées", "small");
            await this.loadTraining(trainingId as string);
          } else {
            feedback.error(`Une erreur est survenue`, "small");
          }
        }
      }
    },

    async addSession() {
      if (this.nav === TrainingNavEnum.SESSIONS) {
        this.training.errors = {};
        await this.validation();

        if (!Object.keys(this.training.errors).length) {
          if (this.training.sessions.session_draft) {
            this.training.sessions.session_draft.sessionItemId =
              this.training.sessions.session_draft.sessionItemType === SessionItemTypeEnum.INDIVIDUAL
                ? (this.training.session_items.individual.session_item_id as string)
                : (this.training.session_items.group.session_item_id as string);

            if (!this.training.sessions.session_draft.trainingAddress) {
              this.training.sessions.session_draft.trainingAddress =
                this.training.sessions.session_draft.sessionItemType === SessionItemTypeEnum.INDIVIDUAL
                  ? (this.training.session_items.individual.location as string)
                  : (this.training.session_items.group.location as string);
            }

            this.training.sessions.session_draft.isNewSession = true;
            this.training.sessions.sessionList.push(this.training.sessions.session_draft);
          }

          await this.editTraining();

          this.training.sessions.show_add_session = false;

          this.training.sessions.session_draft = {
            trainingAddress: null,
            sessionItemType: null,
            format: null,
            startDate: null,
            endDate: null,
            designation: null,
            sessionTimeslot: [],
            currentSessionTimeslot: null,
          };
        }
      }
    },
    async validation() {
      const trainingValidation = useTrainingValidation();
      this.training.errors = {};

      if (this.nav === TrainingNavEnum.GENERAL) await trainingValidation.validateStepGeneral();
      if (this.nav === TrainingNavEnum.CONTENT) await trainingValidation.validateStepContent();
      if (this.nav === TrainingNavEnum.SESSION_ITEM) await trainingValidation.validateStepSessionItem();
      if (this.nav === TrainingNavEnum.MODALITIES) await trainingValidation.validateStepSessionItem();
      if (this.nav === TrainingNavEnum.SESSIONS) {
        await trainingValidation.validateStepSession();
      }
    },
    openAddSession() {
      this.training.sessions.show_add_session = !this.training.sessions.show_add_session;

      this.training.sessions.session_draft = {
        trainingAddress: null,
        sessionItemType: null,
        format: null,
        startDate: null,
        endDate: null,
        designation: null,
        sessionTimeslot: [],
        currentSessionTimeslot: null,
      };

      this.training.errors = {};
    },
    async assignTagsToItems(
      companyId: string,
      tagType: TagCategoryEnum,
      itemType: SdkItemType,
      tags?: TagDetails[] | null,
      item_ids?: string[] | string,
    ) {
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
      // assign en masse
      if (Array.isArray(item_ids) && item_ids?.length) {
        const assignTagDto: any[] = [];
        for (const id of item_ids) {
          // if training tag = tags = []
          if (Array.isArray(tags) && tags.length) {
            for (const tag of tags) {
              assignTagDto.push({
                itemId: id,
                itemType,
                tagId: tag.tagId,
                category: tagType,
                name: tag?.name,
              });
            }
          } else {
            // remove assign tag
            assignTagDto.push({
              itemId: id,
              itemType,
              tagId: undefined,
              category: tagType,
              name: undefined,
            });
          }
        }
        await apiInstance.assignTagsToItems({
          companyId,
          assignTagDto,
        });
        // simple assign
      } else if (item_ids && !Array.isArray(item_ids)) {
        // if training tag = tags[]
        if (Array.isArray(tags) && tags.length) {
          const assignTagDto: any[] = [];
          for (const tag of tags) {
            assignTagDto.push({
              itemId: item_ids,
              itemType,
              tagId: tag.tagId,
              category: tagType,
              name: tag?.name,
            });
          }
          await apiInstance.assignTagsToItems({
            companyId,
            assignTagDto,
          });
        } else {
          await apiInstance.assignTagsToItems({
            companyId,
            assignTagDto: [
              {
                itemId: item_ids,
                itemType,
                tagId: undefined,
                category: tagType,
                name: undefined,
              },
            ],
          });
        }
      }
    },
  },
});
