import type { AsyncData } from "#app/composables/asyncData";
import { useFetchApi } from "~/composables/api/useFetchApi";

export const useSkillsApi = () => {
  async function fetchSkills(): Promise<AsyncData<any, any>> {
    return await useFetchApi("trainings", `/skills`, {
      method: "GET",
    });
  }

  return { fetchSkills };
};
