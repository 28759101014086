import type { AsyncData } from "#app/composables/asyncData";
import { useFilters } from "../filters/useFilters";
import { usePagination } from "../paginations/usePagination";
import { useFetchApi } from "~/composables/api/useFetchApi";
import type { IUpdateUserHabilitationPayload } from "~~/utils/interfaces/habilitations-interfaces";
import type { ICreateUserHabilitationPayload } from "~~/utils/interfaces/users-interfaces";

export const useUsersHabilitationsApi = () => {
  async function fetchAvailableHabilitations(): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/habilitations/availableHabilitations`, {
      method: "GET",
    });
  }

  async function createUpdate(name: string, duration_years: number): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", "/habilitations/createHabilitation", {
      method: "POST",
      body: { name, duration_years },
    });
  }

  // All users infos
  async function fetchUsersHabilitations(): Promise<AsyncData<any, any>> {
    const pagination = usePagination();
    const filters = useFilters();

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);
    if (!paginate_options.sorting_column) {
      paginate_options.sorting_column = "default";
    }

    const paginate_query = new URLSearchParams(paginate_options).toString();

    const filters_query = filters.getFiltersPayload();
    const query_string = [paginate_query, filters_query].filter(Boolean).join("&");
    return await useFetchApi("auth", `/habilitations/allUsersHabilitations?${query_string}`, {
      method: "GET",
    });
  }

  async function fetchUserHabilitationDetails(userHabilitationId: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/habilitations/userHabilitation/${userHabilitationId}`, {
      method: "GET",
    });
  }

  async function fetchCertificateUrl(userHabilitationId: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/habilitations/getCertificateUrl/${userHabilitationId}`, {
      method: "GET",
    });
  }

  async function createUserHabilitation(
    createUserPayload: ICreateUserHabilitationPayload,
  ): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/habilitations/userHabilitation`, {
      method: "POST",
      body: createUserPayload,
    });
  }

  async function updateUserHabilitation(
    userHabilitationId: string,
    updateUserHabilitationPayload: IUpdateUserHabilitationPayload,
  ): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/habilitations/userHabilitation/${userHabilitationId}`, {
      method: "PATCH",
      body: updateUserHabilitationPayload,
    });
  }

  async function uploadCertificate(userHabilitationId: string, file: any): Promise<AsyncData<any, any>> {
    const formData = new FormData();
    formData.append("file", file);
    file.progress = 25;
    const response = await useFetchApi("auth", `habilitations/uploadCertificate/${userHabilitationId}`, {
      method: "POST",
      body: formData,
    });
    file.progress = 100;
    file.status = !response.error.value?.statusCode;
    return response;
  }

  async function removeUserHabilitation(userHabilitationId: string): Promise<AsyncData<any, any>> {
    const response = await useFetchApi("auth", `habilitations/removeUserHabilitation/${userHabilitationId}`, {
      method: "DELETE",
    });
    return response;
  }

  async function removeCertificate(userHabilitationId: string): Promise<AsyncData<any, any>> {
    const response = await useFetchApi("auth", `habilitations/removeCertificate/${userHabilitationId}`, {
      method: "DELETE",
    });
    return response;
  }

  async function fetchAllUserHabilitations(userId: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/habilitations/getAllUserHAbilitations/${userId}`, {
      method: "GET",
    });
  }

  async function fetchNotExcludedUserHabilitation(userIdList: string[]): Promise<AsyncData<any, any>> {
    const filters = useFilters();

    const filters_query = filters.getFiltersPayload();
    const query_string = [filters_query].filter(Boolean).join("&");

    return await useFetchApi("auth", `/habilitations/getUsersNotExcluded?${query_string}`, {
      method: "GET",
      query: {
        usersList: userIdList,
      },
    });
  }

  async function exportUserHabilitationsCSV(): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/habilitations/export/csv`, {
      method: "GET",
    });
  }

  async function exportUserHabilitationsXLSX(): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/habilitations/export/xlsx`, {
      method: "GET",
    });
  }

  return {
    fetchNotExcludedUserHabilitation,
    fetchCertificateUrl,
    fetchUsersHabilitations,
    fetchUserHabilitationDetails,
    createUserHabilitation,
    fetchAvailableHabilitations,
    updateUserHabilitation,
    uploadCertificate,
    removeUserHabilitation,
    removeCertificate,
    fetchAllUserHabilitations,
    createUpdate,
    exportUserHabilitationsCSV,
    exportUserHabilitationsXLSX,
  };
};
