import { useFetchApi } from "~/composables/api/useFetchApi";
import type {
  IEndOfTraining,
  IRegulationBookingPayload,
  IUpdateBookingSessionPayload,
  IUpdateBookingPayload,
  ICreateBookingPayload,
  IEndOfTrainingForUsersList,
  IEditEndOfTraining,
} from "~~/utils/interfaces/bookings-interfaces";
import type { ICreateBookingEvent, ICreateNotificationEventPayload } from "~~/utils/interfaces/event-interfaces";

export const useBookingsApi = () => {
  async function createBooking(createBookings: ICreateBookingPayload) {
    return await useFetchApi("orders", `bookings`, {
      method: "POST",
      body: createBookings,
    });
  }

  async function setEnfOfTraining(endOfTraining: IEndOfTraining) {
    return await useFetchApi("orders", `bookings/trainer/setEndOfTraining`, {
      method: "POST",
      body: endOfTraining,
    });
  }
  async function editEnfOfTraining(endOfTraining: IEditEndOfTraining, bookingId: string) {
    return await useFetchApi("orders", `/bookings/no-show/${bookingId}`, {
      method: "PATCH",
      body: endOfTraining,
    });
  }
  async function setEndOfTrainingForUsersList(endOfTrainingForUsersList: IEndOfTrainingForUsersList) {
    return await useFetchApi("orders", `bookings/trainer/setEndOfTrainingForUsersList`, {
      method: "PATCH",
      body: endOfTrainingForUsersList,
    });
  }
  async function updateBookingSession(updateBookingSessionPayload: IUpdateBookingSessionPayload) {
    return await useFetchApi("orders", `bookings/trainer/updateBookingSession`, {
      method: "POST",
      body: updateBookingSessionPayload,
    });
  }

  async function fetchCompanyBookingDetails(bookingId: string) {
    return await useFetchApi("orders", `bookings/company/${bookingId}`, {
      method: "GET",
    });
  }

  async function regulateBooking(regulateBookingPayload: IRegulationBookingPayload) {
    return await useFetchApi("orders", `admin/bookings/regulateBookings`, {
      method: "POST",
      body: regulateBookingPayload,
    });
  }

  async function cancelBooking(bookingId: string, doNotNotify: boolean, requestId?: string | null) {
    return await useFetchApi("orders", `bookings/cancelBooking/${bookingId}`, {
      method: "PATCH",
      body: { do_not_notify: doNotNotify, request_id: requestId },
    });
  }

  async function sendEvent(createBookingEvent: ICreateBookingEvent) {
    return await useFetchApi("orders", `bookingsEvents/triggerEvent`, {
      method: "POST",
      body: createBookingEvent,
    });
  }

  async function sendNotificationEvent(createNotificationEventPayload: ICreateNotificationEventPayload) {
    return await useFetchApi("orders", `bookingsEvents/triggerNotificationEvent`, {
      method: "POST",
      body: createNotificationEventPayload,
    });
  }

  async function getNotificationTemplate(sessionId: string, toManagers: boolean, language: string) {
    return await useFetchApi("orders", `bookingsEvents/triggerNotificationEvent/getTemplate/${sessionId}`, {
      method: "GET",
      query: {
        to_managers: toManagers,
        language,
      },
    });
  }

  async function confirmBooking(bookingId: string, sessionId: string) {
    return await useFetchApi("orders", `bookings/confirmBooking/${bookingId}`, {
      method: "PATCH",
      body: { session_id: sessionId },
    });
  }

  async function updateBooking(bookingId: string, updateBookingPayload: IUpdateBookingPayload) {
    return await useFetchApi("orders", `bookings?booking_id=${bookingId}`, {
      method: "PATCH",
      body: updateBookingPayload,
    });
  }

  async function refuseBooking(bookingId: string) {
    return await useFetchApi("orders", `bookings/refuseBooking/${bookingId}`, {
      method: "DELETE",
    });
  }

  return {
    sendNotificationEvent,
    getNotificationTemplate,
    sendEvent,
    updateBooking,
    createBooking,
    setEnfOfTraining,
    editEnfOfTraining,
    updateBookingSession,
    cancelBooking,
    regulateBooking,
    fetchCompanyBookingDetails,
    confirmBooking,
    refuseBooking,
    setEndOfTrainingForUsersList,
  };
};
