import { TagCategoryEnum, TagsApi, ItemType as SdkItemType } from "@simbelapp/order-sdk";
import { defineStore } from "pinia";
import { useSDKApi } from "~/composables/api/useSDKApi";
import { useCatalogApi } from "~/composables/catalog/useCatalogApi";
import { useFilters } from "~/composables/filters/useFilters";
import { usePagination } from "~/composables/paginations/usePagination";
import { MicroserviceEnum } from "~/utils/enums/common.enums";
import { useFeedback } from "~~/composables/feedback/useFeedback";
import type { ICatalogStore } from "~~/utils/interfaces/catalog-interfaces";

export const useCatalogStore = defineStore("catalogStore", {
  state: (): ICatalogStore => {
    return {
      employeesCatalog: null,
      show_csv_import_modal: false,
      companyCatalog: null,
      count: {
        exists: 0,
        trainings: 0,
      },
      deleted: 0,
      selectedItems: [],
      excludedItems: [],
      selectedAll: false,
      showDeleteCatalog: false,
      showEditTagModal: false,
    };
  },
  getters: {},
  actions: {
    async fetchCatalog() {
      const catalogApi = useCatalogApi();

      const { data } = await catalogApi.fetchCompanyCatalog();

      if (data.value) {
        this.companyCatalog = data.value;
      }
    },
    async fetchEmployeesCatalog(trainingId?: string) {
      const catalogApi = useCatalogApi();

      const { data } = await catalogApi.fetchAllEmployeeCatalog({
        trainingId,
        isVisible: true,
        isLive: true,
      });
      if (data.value) {
        this.employeesCatalog = data.value.data;
      }
    },

    async fetchEmployeesCatalogUnfiltered(lazy?: boolean, trainingId?: string) {
      const catalogApi = useCatalogApi();

      const { data } = await catalogApi.fetchEmployeeCatalog({
        trainingId,
        ...(!lazy && {
          limit: "custom",
        }),
      });

      if (data.value && !lazy) {
        this.employeesCatalog = data.value.data;
      } else {
        this.employeesCatalog = [];
      }
    },

    async fetchCompanyCatalog(my_trainings?: boolean) {
      const catalogApi = useCatalogApi();

      const { data } = await catalogApi.fetchEmployeeCatalog({ isLive: true, my_trainings });

      if (data.value) {
        this.companyCatalog = data.value;
      }
    },

    async changeVisibility(trainingIds: string[], isVisible: boolean, selectedAll = false): Promise<any> {
      const catalogApi = useCatalogApi();
      const feedback = useFeedback();
      const { data } = await catalogApi.changeVisibility(trainingIds, isVisible, selectedAll);

      if (data.value) {
        return data.value;
      } else {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },

    async addTrainingToCatalog(selectedIds: string[], selectAll: boolean, excludedIds?: string[]): Promise<any> {
      const catalogApi = useCatalogApi();
      const feedback = useFeedback();

      const { data } = await catalogApi.addTrainingToCatalog(selectAll, selectedIds, excludedIds);

      if (data.value) {
        this.count = data.value.count;
      } else {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },

    async deleteTrainingFromCatalog(selectedIds: string[], selectAll: boolean, excludedIds?: string[]): Promise<any> {
      const catalogApi = useCatalogApi();
      const feedback = useFeedback();

      const { data } = await catalogApi.deleteTrainingFromCatalog(selectAll, selectedIds, excludedIds);

      if (data.value) {
        this.deleted = data.value.count.trainings;
      } else {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },

    async batchAssignTrainingTags(
      selectAll: boolean,
      itemType: SdkItemType,
      tagIds: string[],
      tagCategory: TagCategoryEnum,
      itemIds?: string[],
    ) {
      const pagination = usePagination();
      const filters = useFilters();
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
      const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);
      const paginate_query = new URLSearchParams(paginate_options);
      const query_string = Object.fromEntries(paginate_query.entries());
      const filters_query = filters.getFiltersForSDK();
      try {
        await apiInstance.batchAssignTagsToTrainings({
          batchAssignTagsDto: {
            selectAll,
            tagIds,
            itemIds: itemIds ?? [],
            tagCategory,
            itemType,
          },
          ...query_string,
          ...filters_query,
        });
      } catch (error) {
        // feedback.error(`Une erreur est survenue`, "small");
      }
    },
  },
});
