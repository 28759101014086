import { defineStore } from "pinia";
import { useCatalogApi } from "~/composables/catalog/useCatalogApi";
import type { IHomeCatalogStore } from "~~/utils/interfaces/catalog-interfaces";
import { useCategoriesApi } from "~/composables/categories/useCategoriesApi";
import { usePagination } from "~/composables/paginations/usePagination";

export const useHomeCatalogStore = defineStore("homeCatalogStore", {
  state: (): IHomeCatalogStore => {
    return {
      companyCatalog: null,
      offset: 0,
      maxOffset: 0,
      catalogFetchLoading: false,
      categoryCatalog: null,
      selectedCategory: null,
      searchCatalog: null,
      showTrainingPageModal: false,
      hideCatalogPrices: false,
    };
  },
  getters: {},
  actions: {
    resetPaginate() {
      const paginate = usePagination();
      this.companyCatalog = null;
      this.offset = 0;
      this.maxOffset = 0;
      paginate.reset();
    },
    async fetchCompanyCatalog(fetchByScroll?: boolean) {
      this.catalogFetchLoading = true;
      const paginate = usePagination();
      const catalogApi = useCatalogApi();
      if (!fetchByScroll) {
        paginate.resetOffset();
      }
      const { data } = await catalogApi.fetchCompanyCatalog();

      if (data.value) {
        if (this.companyCatalog === null) this.companyCatalog = [];
        if (fetchByScroll) {
          this.companyCatalog.push(...data.value.data);
          this.offset += data.value.data.length;
          this.maxOffset = data.value.meta.count;
          this.hideCatalogPrices = data.value.meta.hide_catalog_prices;
          paginate.paginateOptions.value.offset = this.offset;

          this.catalogFetchLoading = false;
        } else {
          this.companyCatalog = data.value.data;
          this.offset += data.value.data.length;
          this.maxOffset = data.value.meta.count;
          this.hideCatalogPrices = data.value.meta.hide_catalog_prices;
          paginate.paginateOptions.value.offset = this.offset;
          this.catalogFetchLoading = false;
        }
      }
    },
    async fetchCatalogCategories() {
      const categoriesApi = useCategoriesApi();

      const { data } = await categoriesApi.fetchCatalogCategories();

      if (data.value) {
        this.categoryCatalog = data.value;
      }
    },
  },
});
