import { defineStore } from "pinia";
import { useCategoriesApi } from "~~/composables/categories/useCategoriesApi";
import { useFeedback } from "~~/composables/feedback/useFeedback";
import type { ICategoriesStore, ICategory } from "~~/utils/interfaces/categories-interfaces";

export const useCategoriesStore = defineStore("categoriesStore", {
  state: (): ICategoriesStore => {
    return {
      categories: null,
      editDetails: false,
    };
  },
  getters: {},
  actions: {
    async fetchCategories() {
      const categoriesApi = useCategoriesApi();

      const { data } = await categoriesApi.fetchCategories();

      if (data.value) {
        this.categories = data.value;
      }
    },
    async fetchAllCategories() {
      const categoriesApi = useCategoriesApi();

      const { data } = await categoriesApi.fetchAllCategories();

      if (data.value) {
        this.categories = data.value;
      }
    },
    async createUpdateCategories(categories: ICategory[]) {
      const categoriesApi = useCategoriesApi();
      const feedback = useFeedback();

      try {
        await categoriesApi.createUpdateCategories(categories);
      } catch (error) {
        feedback.error(`erreur`, "small");
      }
    },
  },
});
