import {
  ActivitiesApi,
  ScormApi,
  TrainingsLmsApi,
  type ActivityDetails,
  type UpdateQuizDto,
  ActivityDetailsTypeEnum,
} from "@simbelapp/trainings-sdk";
import { defineStore } from "pinia";
import type { ErrorObject } from "@vuelidate/core";
import { useCreateBuilderTrainingStore } from "../trainings/create-training-builder.store";
import { useSDKApi } from "~/composables/api/useSDKApi";
import { useFeedback } from "~/composables/feedback/useFeedback";
import { MicroserviceEnum } from "~/utils/enums/common.enums";
import type { IActivitiesStore } from "~/utils/interfaces/activities-interfaces";
import { TypeBuilderPanelEnum } from "~/utils/enums/training.enums";
import type { QuizTypeEnum } from "~/utils/enums/activities.enums";

export const useActivitiesStore = defineStore("activitiesStore", {
  state: (): IActivitiesStore => {
    return {
      activities: [],
      currentActivity: null,
      isPreview: false,
      errors: [],
      quizzesErrors: [],
      isLoaded: false,
    };
  },
  getters: {
    getActivityById: (state) => (id: string) => {
      return state.activities?.find((activity: ActivityDetails) => {
        if (activity.activityId && activity.activityId === id) {
          return activity;
        }
        return null;
      });
    },
  },
  actions: {
    async createActivityQuiz(activityId: string, type: QuizTypeEnum, trainingId: string) {
      const feedback = useFeedback();
      const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, ActivitiesApi);

      try {
        const quiz = await apiInstance.createActivityQuiz({
          activityId,
          createQuizDto: {
            type,
          },
        });

        if (quiz) {
          await this.getActivitiesList(trainingId);
          await this.getActivityDetails(activityId);
        }
        return null;
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },
    async createActivity(trainingId: string) {
      this.isLoaded = false;
      const feedback = useFeedback();
      const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, ActivitiesApi);

      try {
        const activity = await apiInstance.createActivity({
          createActivityDto: {
            trainingId,
          },
        });

        if (activity) {
          const activitiesList = await apiInstance.getActivities({ trainingId });

          this.activities = [...activitiesList];
          this.currentActivity = activity;
          return activity.activityId;
        }
        return null;
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },

    async getActivityDetails(activityId: string) {
      const feedback = useFeedback();
      const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, ActivitiesApi);

      try {
        const activity = await apiInstance.getActivity({
          activityId,
        });

        this.currentActivity = activity;
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },

    async getActivitiesList(trainingId: string) {
      if (trainingId) {
        const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, ActivitiesApi);
        const activities = await apiInstance.getActivities({ trainingId });
        this.activities = activities;
      }
    },

    async updateActivityQuiz(quizId: string, elements: string[]) {
      const feedback = useFeedback();
      const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, ActivitiesApi);

      try {
        const currentQuiz = this.currentActivity?.quizzesDetails?.find((q) => q.quizId === quizId);
        if (this.currentActivity && currentQuiz) {
          const updateQuizDto = {} as any;
          for (const element of elements) {
            updateQuizDto[element as keyof UpdateQuizDto] = currentQuiz[element as keyof typeof currentQuiz];
          }
          updateQuizDto.type = currentQuiz.type;
          const quiz = await apiInstance.updateActivityQuiz({
            activityId: this.currentActivity.activityId,
            quizId,
            updateQuizDto,
          });

          const quizItem = this.currentActivity?.quizzesDetails?.find((item) => item.quizId === quiz.quizId);
          if (quizItem && (!quizItem.quizAnswers || !quizItem.quizAnswers.length)) {
            quizItem.quizAnswers = quiz.quizAnswers;
          }
        }
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },
    async updateActivity(element: string[], trainingId: string) {
      const feedback = useFeedback();
      const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, ActivitiesApi);
      try {
        // Create payload for the update
        const payload: any = {};
        for (const el of element) {
          if (
            this.currentActivity &&
            this.currentActivity.activityId &&
            Object.prototype.hasOwnProperty.call(this.currentActivity, el)
          ) {
            // add property to the payload object
            payload[el] = this.currentActivity[el as keyof typeof this.currentActivity];
          }
        }

        // Check if the payload have at least 1 key
        if (Object.keys(payload).length !== 0 && this.currentActivity?.activityId) {
          const activity = await apiInstance.updateActivity({
            activityId: this.currentActivity.activityId,
            updateActivityDto: {
              ...payload,
            },
          });
          if (activity.type === ActivityDetailsTypeEnum.Quiz && payload.type) {
            await this.getActivityDetails(activity.activityId);
          }
          if (activity) {
            const activitiesList = await apiInstance.getActivities({ trainingId });
            this.activities = [...activitiesList];
          }
          this.isLoaded = true;
        }
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },
    async duplicateActivityQuiz(quizId: string, activityId: string) {
      const feedback = useFeedback();
      const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, ActivitiesApi);

      try {
        const activityQuiz = await apiInstance.duplicateActivityQuiz({
          quizId,
          activityId,
        });

        if (activityQuiz) {
          await this.getActivityDetails(activityId);
        }
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },
    async duplicateActivity(activityId: string, trainingId: string) {
      const feedback = useFeedback();
      const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, ActivitiesApi);

      try {
        const activity = await apiInstance.duplicateActivity({
          activityId,
        });

        if (activity) {
          const activitiesList = await apiInstance.getActivities({ trainingId });
          this.currentActivity = activity;
          this.activities = [...activitiesList];
        }
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },
    async removeActivityQuiz(quizId: string, activityId: string) {
      const feedback = useFeedback();
      const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, ActivitiesApi);

      try {
        await apiInstance.deleteActivityQuiz({
          quizId,
          activityId,
        });

        await this.getActivityDetails(activityId);
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },
    async removeActivity(activityId: string, trainingId: string) {
      const feedback = useFeedback();
      const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, ActivitiesApi);
      const createBuilderTrainingStore = useCreateBuilderTrainingStore();
      try {
        await apiInstance.deleteActivity({
          activityId,
        });

        const activitiesList = await apiInstance.getActivities({ trainingId });
        this.activities = [...activitiesList];

        if (this.activities.length > 0) {
          createBuilderTrainingStore.currentTab = TypeBuilderPanelEnum.ACTIVITY;
          this.getActivityDetails(this.activities[this.activities.length - 1].activityId);
        } else {
          createBuilderTrainingStore.currentTab = TypeBuilderPanelEnum.TRAINING;
        }
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },
    async updateActivityOrder(trainingId: string) {
      const feedback = useFeedback();
      const apiInstanceTraining = await useSDKApi(MicroserviceEnum.TRAININGS, TrainingsLmsApi);

      try {
        await apiInstanceTraining.updateActivitiesOrder({
          trainingId,
          updateLMSTrainingActivitiesOrdersDto: {
            activitiesOrder: this.activities.map((a) => a.activityId),
          },
        });
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },
    pushValidationErrors(errors: ErrorObject[], activityId: string) {
      errors.forEach((err: ErrorObject) => {
        this.errors.push({ error: { [err.$propertyPath]: err.$message as string }, activityId });
      });
    },
    pushValidationQuizzesErrors(errors: ErrorObject[], activityId: string, quizId?: string) {
      errors.forEach((err: ErrorObject) => {
        this.quizzesErrors.push({
          error: { [err.$propertyPath]: err.$message as string },
          activityId,
          ...(quizId && { quizId }),
        });
      });
    },
    async uploadActivityDocument(file: File, activityId: string) {
      const feedback = useFeedback();
      const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, ActivitiesApi);
      try {
        const data = await apiInstance
          .uploadActivityDocument({
            activityId,
            file,
          })
          .catch((error) => {
            feedback.error(`Une erreur est survenue`, "small");
            throw error;
          });

        this.currentActivity = data;
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },

    async importScormAPI(file: File, activityId: string) {
      const feedback = useFeedback();
      const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, ScormApi);
      try {
        const data = await apiInstance
          .importScormPackage({
            activityId,
            file,
          })
          .catch((error) => {
            feedback.error(`Une erreur est survenue`, "small");
            throw error;
          });

        this.currentActivity = data;
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },
  },
});
