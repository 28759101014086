import { startOfDay, isSameMinute } from "date-fns";

export const handleValueInput = () => {
  function handleMinAndMax(value: string, min: number, max: number) {
    const intValue = parseInt(value, 10);

    if (isNaN(intValue)) {
      return min;
    } else if (intValue < min) {
      return min;
    } else if (intValue > max) {
      return max;
    } else {
      return intValue;
    }
  }

  function formatTime(time?: Date) {
    const hours = time ? new Date(time)?.getHours().toString().padStart(2, "0") : "00";
    const minutes = time ? new Date(time)?.getMinutes().toString().padStart(2, "0") : "00";

    return `${hours}:${minutes}`;
  }

  function parseToDate(timeString: string, model?: Date) {
    const [hours, minutes] = timeString.split(":");
    const date = model ? new Date(model) : new Date();

    date.setHours(Number(hours), Number(minutes), 0, 0);
    return date;
  }

  function checkDates(startDate: Date, endDate: Date) {
    const isStartDateValid = startDate && !isSameMinute(new Date(startDate), startOfDay(new Date(startDate)));
    const isEndDateValid = endDate && !isSameMinute(new Date(endDate), startOfDay(new Date(endDate)));

    if (startDate > endDate && isEndDateValid && isStartDateValid) {
      return "L’heure de fin doit être ultérieure à l’heure de début";
    } else if (isStartDateValid && !isEndDateValid) {
      return "L'heure de fin est obligatoire si l'heure de début est renseignée.";
    } else if (!isStartDateValid && isEndDateValid) {
      return "L'heure de début est obligatoire si l'heure de fin est renseignée.";
    }
  }
  function floatNumber(num: number) {
    return Number.isInteger(num) ? num : parseFloat(num.toFixed(2));
  }
  return { handleMinAndMax, formatTime, parseToDate, checkDates, floatNumber };
};
