import { defineStore } from "pinia";
import type {
  IUpdateUserHabilitation,
  IUpdateUserHabilitationPayload,
  IUserHabilitationDetails,
} from "~~/utils/interfaces/habilitations-interfaces";
import { useUsersHabilitationsStore } from "~~/store/users-habilitations/users-habilitations.store";
import { useUsersHabilitationsApi } from "~~/composables/users-habilitations/useUsersHabilitationsApi";
import { useFeedback } from "~~/composables/feedback/useFeedback";
import { usePagination } from "~~/composables/paginations/usePagination";

export const useUpdateUserHabilitationsStore = defineStore("useUpdateUserHabilitationsStore", {
  state: (): IUpdateUserHabilitation => {
    return {
      show_to_renew_modal: false,
      show_to_remove_modal: false,
      userUpdate: {
        user_id: null,
        habilitation_name: null,
        duration_years: null,
      },
    };
  },
  getters: {},
  actions: {
    async validateUserUpdate() {},

    openUserDetailsModal() {
      this.$reset();
      const uersHabilitationsStore = useUsersHabilitationsStore();
      uersHabilitationsStore.cleanValidationError();
    },

    async updateUserRenew(userHabilitationId: string, toRenew: boolean) {
      const usersHabilitationsApi = useUsersHabilitationsApi();
      const usersHabilitationsStore = useUsersHabilitationsStore();
      const feedback = useFeedback();
      const { data } = await usersHabilitationsApi.updateUserHabilitation(userHabilitationId, {
        to_renew: toRenew,
      });

      if (data.value) {
        feedback.success("Vos modifications ont bien été sauvegardées", "small");
        return data.value;
      } else {
        usersHabilitationsStore.updateRow(userHabilitationId, "to_renew", !toRenew);
        feedback.error(`Une erreur est survenue`, "small");
      }
    },

    async updateUserHabilitation(userHabilitationDetails: IUserHabilitationDetails) {
      const feedback = useFeedback();
      const usersHabilitationsStore = useUsersHabilitationsStore();
      const usersHabilitationsApi = useUsersHabilitationsApi();
      const { user_habilitation_details } = usersHabilitationsStore;

      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        habilitation_id,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        habilitation_status,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        user_display_value,
        user_habilitation_id,
        document_link,
        certificate,
        ...body
      } = userHabilitationDetails;

      const updateUserHabilitationPayload: IUpdateUserHabilitationPayload = body;
      if (user_habilitation_id) {
        const removeDocument: boolean = (user_habilitation_details?.document_link && !document_link) || false;
        await this.updateDocument(user_habilitation_id, removeDocument, certificate);

        const { data } = await usersHabilitationsApi.updateUserHabilitation(
          user_habilitation_id,
          updateUserHabilitationPayload,
        );

        if (data.value) {
          feedback.success("Vos modifications ont bien été sauvegardées", "small");
          return data.value;
        } else {
          feedback.error(`Une erreur est survenue lors de l'ajout`, "small");
        }
      }
    },

    async updateDocument(userHabilitationId: string, removeDocument: boolean, certificate: File | null) {
      const feedback = useFeedback();
      let data: any = {};
      const usersHabilitationsStore = useUsersHabilitationsStore();
      if (certificate) {
        data = await usersHabilitationsStore.uploadCertificate(userHabilitationId, certificate);
      } else if (removeDocument) {
        data = await usersHabilitationsStore.removeCertificate(userHabilitationId);
      }
      if (!data) {
        feedback.error(`Une erreur est survenue lors de l'ajout`, "small");
      }
    },

    async removeUserHabilitation(userHabilitationId: string) {
      const feedback = useFeedback();
      const pagination = usePagination();
      const usersHabilitationsStore = useUsersHabilitationsStore();
      const usersHabilitationsApi = useUsersHabilitationsApi();

      if (userHabilitationId) {
        const { data } = await usersHabilitationsApi.removeUserHabilitation(userHabilitationId);

        if (data.value) {
          await pagination.fetchData();
          this.show_to_remove_modal = false;
          usersHabilitationsStore.show_user_habilitation_detail_modal = false;
          feedback.success("L'habilitation a bien été supprimée", "small");
          return data.value;
        } else {
          feedback.error(`Une erreur est survenue lors de l'ajout`, "small");
        }
      }
    },
  },
});
