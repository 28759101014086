import { defineStore } from "pinia";
import { addDays } from "date-fns";
import {
  TrainingPlanApi,
  TrainingPlanDetailsItemTypeEnum,
  type TrainingPlanDetails,
  SessionsApi,
  TagsApi,
  TagCategoryEnum,
  type TagDetails,
  BookingsApi,
  TimeslotsApi,
  type TimeslotAttendeeDTO,
} from "@simbelapp/order-sdk";
import { ItemType as SdkItemType } from "@simbelapp/order-sdk";
import { useCreateProjectCoreStore } from "../project/create-project-core.store";
import type {
  TrainingPlanIndividual,
  ISessionsDetailsStore,
  IUpdateSession,
} from "~/utils/interfaces/sessions-interfaces";

import { useFeedback } from "~/composables/feedback/useFeedback";
import { useSessionsApi } from "~/composables/sessions/useSessionsApi";
import { ItemType, MicroserviceEnum, VatEnum } from "~/utils/enums/common.enums";
import {
  AttendeesConfirmEnum,
  AttendeesPendingEnum,
  SessionDetailsEndNavEnum,
  SessionDetailsSatisfactionTypeEnum,
} from "~/utils/enums/session-details-enums";
import { useUploadApi } from "~~/composables/utils/useUploadApi";
import type { IFinancingComponent } from "~~/utils/interfaces/common-interfaces";
import { SessionItemTypeEnum } from "~~/utils/enums/session-items.enums";
import { useCatalogApi } from "~~/composables/catalog/useCatalogApi";
import { useTrainingsPlansApi } from "~~/composables/trainings-plans/useTrainingsPlansApi";
import { useSessionsStore } from "~~/store/sessions/sessions-store";
import { BookingEventsEnum, BookingStatusEnum } from "~~/utils/enums/bookings-events.enums";
import { useBookingsApi } from "~~/composables/bookings/useBookingsApi";
import { EvenStatusEnum } from "~~/utils/enums/event-status.enum";
import { useProjectApi } from "~~/composables/projects/useProjectApi";
import { useSDKApi } from "~/composables/api/useSDKApi";
import { useBillingsStore } from "~/store/billings/billings.store";
import { usePagination } from "~/composables/paginations/usePagination";
import { useFilters } from "~/composables/filters/useFilters";
import { handleValueInput } from "~/utils/helpers/handleValueInput";

export const useSessionDetailsStore = defineStore("sessionDetailsStore", {
  state: (): ISessionsDetailsStore => {
    return {
      session_details: null,
      session_details_attendees: null,
      session_timeslots: null,
      currentTimeslot: {
        timeslot: null,
        timeslotAttendees: null,
      },
      tmp_session_timeslots: {
        timeslotId: null,
        session_timeslots: null,
      },
      openAccordionAttendees: null,
      showEditTimeslotModalConfirm: null,
      showEditTimeslot: null,
      showAddTimeslot: false,
      showDeclareAttendances: {
        stepDeclaration: null,
        endTraining: false,
        showModal: false,
        endTrainingSessionModalConfirmation: false,
      },
      showNotificationModalConfirm: false,
      nb_max_attendees: null,
      total_external_costs: null,
      total_attendees: null,
      session_documents: null,
      showSessionRemoveDocumentModal: false,
      showEditSessionModal: false,
      showAddAttendeeModal: false,
      showConfirmUserSession: false,
      confirmInviteUserSession: {
        notification_type: BookingStatusEnum.BOOKING_CONFIRMED,
        comment: null,
      },
      showEditCompletionRateModal: false,
      show_modal: false,
      documentId: null,
      sessionsDocuments: null,
      isLoadingDocumentFile: null,
      users_buckets: null,
      session_training: null,
      sign_in_sheet: {
        sign_in_sheet_types: null,
        show_sign_in_sheet_modal: null,
        can_upload: null,
        files: null,
      },
      sign_in_sheet_type: null,
      sign_in_sheet_modal: false,
      // end_add_training_modal: false,
      end_training_session: {
        end_training_session_modal_confirmation: false,
        end_training_session_modal: false,
        show_file_upload: false,
        nav: SessionDetailsEndNavEnum.SESSION,
        nav_disabled: false,
      },
      session_satisfaction: {
        direct: {
          type: SessionDetailsSatisfactionTypeEnum.DIRECT,
          isChecked: false,
          start_date: addDays(Date.now(), 1),
        },
        delayed: {
          type: SessionDetailsSatisfactionTypeEnum.DELAYED,
          isChecked: false,
          start_date: addDays(Date.now(), 60),
        },
      },
      session_view_modal: false,
      todo_list_modal: false,
      file_upload: {
        show_file_upload: false,
      },
      deleteSession: {
        show_modal: false,
      },
      training_tag: null,
      session_tag: null,
      tmp_training_tag: null,
      tmp_session_tag: null,
      isLms: false,
    };
  },
  getters: {
    isTrainingStatusDraft(): boolean {
      return !!(this.session_training?.status === "draft");
    },

    getFinancingInfo(): IFinancingComponent {
      const { floatNumber } = handleValueInput();

      let usersBuckets = null;
      if (this.session_details?.sessionType === SessionItemTypeEnum.INDIVIDUAL) {
        usersBuckets =
          this.session_details_attendees?.map((u) => {
            const t = floatNumber(u?.price_excl_tax || 0);
            const b = floatNumber(u?.budget_excl_tax || 0);
            const o = floatNumber(u?.opco_excl_tax || 0);
            const c = floatNumber(u?.cpf_excl_tax || 0);

            return {
              user_id: u.user_id,
              display: u.display_value!,
              budget: b,
              opco: o,
              cpf: c,
              total: t,
            };
          }) || null;
      } else if (this.session_details?.sessionType === SessionItemTypeEnum.GROUP) {
        usersBuckets =
          this.session_details_attendees?.map((u) => {
            const b = floatNumber((u?.budget_excl_tax || 0) / (this.session_details_attendees?.length || 1));
            const o = floatNumber((u?.opco_excl_tax || 0) / (this.session_details_attendees?.length || 1));
            const t = floatNumber(u?.budget_excl_tax || 0 + (u?.opco_excl_tax || 0) + 0);

            return {
              user_id: u.user_id,
              display: u.display_value!,
              budget: b,
              opco: o,
              cpf: 0,
              total: t,
            };
          }) || null;
      }

      const financingInfos: IFinancingComponent = {
        isCPFEligible: this.session_training?.is_cpf_eligible ?? false,
        isOpcoEligible: this.session_training?.is_opco_eligible ?? false,
        isWithSession: true,
        isWithTraining: true,
        priceExclTax: this?.session_details?.priceExclTax ?? null,
        totalAttendees: this?.session_details_attendees?.length ?? 0,
        opcoAmountExclTax: this.session_details?.opcoExclTax ?? null,
        budgetAmountExclTax: this.session_details?.budgetExclTax ?? null,
        cpfAmountExclTax: this.session_details?.cpfExclTax ?? null,
        groupPriceExclTax: this.session_training?.group_price_excl_tax ?? null,
        individualPriceExclTax: this.session_training?.individual_price_excl_tax ?? null,
        maxNbAttendees: this?.nb_max_attendees ?? this.session_training?.group_session_item?.max_nb_attendees ?? null,
        isIndividualSession:
          (this.session_training?.individual_session_item &&
            this.session_details?.sessionType === SessionItemTypeEnum.INDIVIDUAL) ??
          false,
        vatType: Number.isFinite(this.session_training?.individual_session_item?.vat) ? VatEnum.TTC : VatEnum.HT,
        usersBuckets,
        trainingIsDraft: this.isTrainingStatusDraft ?? false,
        trainingIsVisible: true,
      };
      return financingInfos;
    },
  },
  actions: {
    setSessionFinancing(financingDatas: IFinancingComponent): void {
      if (this.session_details) {
        this.session_details.budgetExclTax = Number(financingDatas?.budgetAmountExclTax || 0);

        this.session_details.opcoExclTax = Number(financingDatas?.opcoAmountExclTax || 0);

        this.session_details.cpfExclTax = Number(financingDatas?.cpfAmountExclTax || 0);

        this.session_details.priceExclTax = Number(financingDatas?.priceExclTax || 0);
        this.users_buckets =
          financingDatas.usersBuckets?.map((u) => {
            return {
              user_id: u.user_id,
              opco: u.opco,
              budget: u.budget,
              cpf: u.cpf,
            };
          }) ?? null;
      }
    },
    setSessionFinancingGroup(financingDatas: IFinancingComponent): void {
      const { floatNumber } = handleValueInput();
      if (this.session_details) {
        this.session_details.budgetExclTax = floatNumber(Number(financingDatas?.budgetAmountExclTax || 0));

        this.session_details.opcoExclTax = floatNumber(Number(financingDatas?.opcoAmountExclTax || 0));

        this.session_details.cpfExclTax = floatNumber(Number(financingDatas?.cpfAmountExclTax || 0));

        this.session_details.priceExclTax =
          floatNumber(Number(financingDatas?.budgetAmountExclTax) || 0) +
          floatNumber(Number(financingDatas?.opcoAmountExclTax) || 0) +
          floatNumber(Number(financingDatas?.cpfAmountExclTax) || 0);
        this.users_buckets =
          financingDatas.usersBuckets?.map((u) => {
            return {
              user_id: u.user_id,
              opco: floatNumber(this.session_details?.opcoExclTax || 0),
              budget: floatNumber(this.session_details?.budgetExclTax || 0),
              cpf: 0,
            };
          }) ?? null;

        this.session_details_attendees?.forEach((attendee) => {
          if (attendee.user_id) {
            const user = this.users_buckets?.find((u) => u.user_id === attendee.user_id);
            if (user) {
              attendee.opco_excl_tax = floatNumber(user.opco || 0);
              attendee.budget_excl_tax = floatNumber(user.budget || 0);
              attendee.cpf_excl_tax = 0;
            }
          }
        });
      }
    },

    async fetchSessionTrainingDetails() {
      const catalogApi = useCatalogApi();

      if (this.session_details?.trainingId) {
        const { data } = await catalogApi.fetchEmployeeCatalog({
          trainingId: this.session_details?.trainingId,
        });

        this.session_training = data.value.data.at(0);
      }
    },

    async fetchSessionDetails(item_id: string, type: ItemType | TrainingPlanDetailsItemTypeEnum) {
      const feedback = useFeedback();
      const billingStore = useBillingsStore();
      billingStore.reset();
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TrainingPlanApi);
      const data: TrainingPlanDetails = await apiInstance.trainingPlanControllerGetSessionDetails({
        itemId: item_id,
        type,
      });

      this.session_details = data;
      if (data.tags) {
        this.training_tag = data.tags?.filter((t) => t.category === TagCategoryEnum.TrainingTag) ?? null;
        this.session_tag = data.tags?.find((t) => t.category === TagCategoryEnum.SessionTag) ?? null;
      }
      if (this.session_details?.trainingId) {
        await this.fetchSessionTrainingDetails();
      }

      if (!this.session_details) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },
    async fetchSessionTimeslots() {
      const feedback = useFeedback();
      if (this.session_details?.sessionId || this.session_details?.id) {
        try {
          this.session_timeslots = null;
          const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TimeslotsApi);
          const itemsTimeslot = await apiInstance.getItemTimeslots({ itemId: this.session_details?.id });
          this.session_timeslots = itemsTimeslot;
        } catch (error) {
          feedback.error(`Une erreur est survenue`, "small");
        }
      }
    },
    async createSessionTimeslots() {
      const feedback = useFeedback();
      if (this.session_details?.sessionId || this.session_details?.id) {
        try {
          const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TimeslotsApi);
          if (this.tmp_session_timeslots.session_timeslots) {
            await apiInstance.createTimeSlot({
              batchCreateTimeslotsDto: {
                createTimeslotsDto: [this.tmp_session_timeslots.session_timeslots],
              },
            });
            await this.fetchSessionTimeslots();
            await this.fetchSessionDetails(this.session_details.id, this.session_details?.itemType);
            await this.fetchSessionAttendees(this.session_details.id, this.session_details?.itemType);

            feedback.success(`Vos modifications ont été sauvegardées`, "small");
          }
        } catch (error) {
          feedback.error(`Une erreur est survenue lors de la création`, "small");
        }
      }
    },
    async updateSessionTimeslots() {
      const feedback = useFeedback();
      try {
        const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TimeslotsApi);
        if (this.tmp_session_timeslots.session_timeslots && this.tmp_session_timeslots.timeslotId) {
          await apiInstance.updateTimeSlots({
            timeslotId: this.tmp_session_timeslots.timeslotId,
            updateTimeslotDto: this.tmp_session_timeslots.session_timeslots,
          });
          await this.fetchSessionTimeslots();
          if (this.session_details?.sessionId || this.session_details?.id) {
            await this.fetchSessionDetails(
              this.session_details.id ?? this.session_details?.sessionId,
              this.session_details?.itemType,
            );
            feedback.success(`Vos modifications ont été sauvegardées`, "small");
          }
        }
      } catch (error) {
        feedback.error(`Une erreur est survenue lors de la modification`, "small");
      }
    },
    async deleteSessionTimeslots(timeslotId: string) {
      const feedback = useFeedback();
      try {
        const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TimeslotsApi);
        await apiInstance.removeTimeslot({
          timeslotId,
        });
        await this.fetchSessionTimeslots();
      } catch (error) {
        feedback.error(`Une erreur est survenue lors de la modification`, "small");
      }
    },
    async endSessionTimeslots() {
      const feedback = useFeedback();
      try {
        if (this.currentTimeslot.timeslotAttendees && this.currentTimeslot.timeslot?.timeslotId) {
          const attendees: TimeslotAttendeeDTO[] = this.currentTimeslot.timeslotAttendees?.map((timeslotAttendee) => {
            return {
              timeslotAttendeeId: timeslotAttendee.timeslotAttendeesId,
              attendedHours: timeslotAttendee.attendedHours,
            } as TimeslotAttendeeDTO;
          });
          const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TimeslotsApi);
          await apiInstance.endTimeSlot({
            endTimeslotDto: {
              timeslotId: this.currentTimeslot.timeslot?.timeslotId,
              attendees,
            },
          });
          await this.fetchSessionTimeslots();
          if (this.session_details?.sessionId || this.session_details?.itemType === ItemType.SESSION) {
            await this.fetchSessionAttendees(this.session_details?.sessionId, this.session_details?.itemType);
          }
        }
      } catch (error) {
        feedback.error(`Une erreur est survenue lors de la modification`, "small");
      }
    },
    async fetchSessionTimeslotsAttendees(timeslotId: string) {
      const feedback = useFeedback();
      try {
        const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TimeslotsApi);
        const timeslotAttendees = await apiInstance.getTimeslotAttendees({ timeslotId });
        this.currentTimeslot.timeslotAttendees = timeslotAttendees;
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },

    async sentEventPedagogicalReport(event_name: BookingEventsEnum, to_be_sent_on: Date, ids: string[]) {
      const bookingsApi = useBookingsApi();
      const feedback = useFeedback();
      try {
        return await bookingsApi.sendEvent({
          event_name,
          to_be_sent_on,
          item_ids: ids,
          status: EvenStatusEnum.TO_SEND,
        });
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },

    async fetchSessionAttendees(item_id: string, type: ItemType | TrainingPlanDetailsItemTypeEnum) {
      const trainingPlan = useTrainingsPlansApi();
      const { data } = await trainingPlan.fetchOneTrainingPlanDetailsAttendees(item_id as string, type);

      this.session_details_attendees = data.value?.filter((a: any) => a.session_id !== "fake");

      if (this.session_details_attendees?.length) {
        this.total_attendees = {
          Tout: this.session_details_attendees ?? [],
          Absents: this.session_details_attendees?.filter((a: any) => a.status === "no_show") ?? [],
          Participants: this.session_details_attendees?.filter((a: any) => a.completion_rate > 0) ?? [],
          Inscrits:
            this.session_details_attendees?.filter((a: any) =>
              Object.values(AttendeesConfirmEnum).includes(a.status as AttendeesConfirmEnum),
            ) ?? [],
          "En attente":
            this.session_details_attendees?.filter((a: any) =>
              Object.values(AttendeesPendingEnum).includes(a.status as AttendeesPendingEnum),
            ) ?? [],
        };
      }
    },

    async showMySession(id: string, type: ItemType) {
      const router = useRouter();
      const sessionsStore = useSessionsStore();
      // const createProjectStore = useCreateProjectStore();

      // store CORE project
      const createProjectCoreStore = useCreateProjectCoreStore();

      if (id && type) {
        await this.fetchSessionDetails(id, type);
        await this.fetchSessionAttendees(id, type);

        if (this.session_details && type === ItemType.PROJECT) {
          await createProjectCoreStore.processCompleteSessionDetail(id);
        }

        await router.push({
          query: {
            id,
            type,
          },
        });
      }
      createProjectCoreStore.show_edit = false;
      sessionsStore.show_session_modal = false;
      this.session_view_modal = true;
    },

    async updateReferent(projectId: string, referentIds: string[], type: string) {
      const feedback = useFeedback();
      const trainingPlanApi = useTrainingsPlansApi();

      try {
        if (projectId) {
          const response = await trainingPlanApi.updateReferent({
            id: projectId,
            user_ids: referentIds,
            type,
          });

          if (response) {
            return response;
          }
        }
      } catch (error) {
        feedback.error(`Une erreur est survenue lors de la modification`, "small");
      }
    },

    async updateSession(sessionId: string, updateSession: IUpdateSession) {
      const feedback = useFeedback();
      const sessionApi = useSessionsApi();
      try {
        if (sessionId) {
          const { data } = await sessionApi.updateSession(sessionId, updateSession);
          if (data.value) {
            await this.fetchSessionDetails(sessionId, ItemType.SESSION);
            await this.fetchSessionAttendees(sessionId, ItemType.SESSION);
            feedback.success(`Vos modifications ont été sauvegardées`, "small");
          } else {
            feedback.error(`Une erreur est survenue lors de la modification`, "small");
          }
        }
      } catch (error) {
        feedback.error(`erreur`, "small");
      }
    },

    async updateSessionBilling(isNotBillable: boolean) {
      const feedback = useFeedback();
      if (this.session_details?.sessionId) {
        try {
          const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, SessionsApi);
          await apiInstance.sessionsControllerUpdateSessionBilling({
            updateSessionBillingDto: {
              sessionId: this.session_details?.sessionId, // replace with actual session id
              isNotBillable, // replace with actual value
            },
          });
        } catch (error) {
          feedback.error(`Une erreur est survenue lors de la modification`, "small");
        }
      }
    },

    // Update session comment
    async updateSessionComment(itemId: string, type: ItemType, comment: string) {
      const feedback = useFeedback();
      try {
        if (itemId) {
          switch (type) {
            case ItemType.SESSION: {
              const sessionApi = useSessionsApi();
              await sessionApi.updateSessionComment(itemId, comment);
              break;
            }
            case ItemType.PROJECT: {
              const projectApi = useProjectApi();
              await projectApi.updateProjectComment(itemId, comment);
              break;
            }
          }
        }
      } catch (error) {
        feedback.error(`erreur`, "small");
      }
    },

    async removeSessionDocument(documentId: string) {
      const uploadApi = useUploadApi();
      const feedback = useFeedback();
      const { data } = await uploadApi.removeDocument(documentId);
      // TO DO ADD ID
      if (data.value && this.session_details?.id) {
        feedback.success("Document supprimé", "small");
      } else {
        feedback.error(`Une erreur est survenue lors de la suppression`, "small");
      }
    },

    async fetchSessionDocuments(sessionId: string) {
      const sessionApi = useSessionsApi();
      const { data } = await sessionApi.fetchSessionDocuments(sessionId);
      if (data.value) {
        this.session_documents = data.value;
      }
    },

    async fetchMySessionDocuments(sessionId: string) {
      const sessionApi = useSessionsApi();
      const { data } = await sessionApi.fetchMySessionDocuments(sessionId);
      if (data.value) {
        this.session_documents = data.value;
      }
    },
    resetSatisfaction() {
      this.session_satisfaction.direct.isChecked = false;
      this.session_satisfaction.direct.start_date = addDays(Date.now(), 1);

      this.session_satisfaction.delayed.isChecked = false;
      this.session_satisfaction.delayed.start_date = addDays(Date.now(), 60);

      if (this.session_details_attendees?.length) {
        this.session_details_attendees.forEach((attendee) => {
          if (attendee.events?.length) {
            attendee.events.forEach((e) => {
              if (e.event_name === SessionDetailsSatisfactionTypeEnum.DELAYED) {
                this.session_satisfaction.delayed.start_date = e.to_be_sent_on && new Date(e.to_be_sent_on);
                this.session_satisfaction.delayed.isChecked = true;
              } else {
                this.session_satisfaction.direct.start_date = e.to_be_sent_on && new Date(e.to_be_sent_on);
                this.session_satisfaction.direct.isChecked = true;
              }
            });
          }
        });
      }
    },

    async assignTagsToItems(
      companyId: string,
      tagType: TagCategoryEnum,
      itemType: SdkItemType,
      tags?: TagDetails[] | TagDetails | null,
      item_ids?: string[],
    ) {
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
      // assign en masse
      if (item_ids?.length) {
        const assignTagDto: any[] = [];
        for (const id of item_ids) {
          if (Array.isArray(tags) && tags.length) {
            for (const tag of tags) {
              assignTagDto.push({
                itemId: id,
                itemType,
                tagId: tag.tagId,
                category: tagType,
                name: tag?.name,
              });
            }
            // if session tag = tags = {}
          } else if (!Array.isArray(tags)) {
            assignTagDto.push({
              itemId: id,
              itemType,
              tagId: tags?.tagId,
              category: tagType,
              name: null,
            });
          } else {
            // remove assign tag
            assignTagDto.push({
              itemId: id,
              itemType,
              tagId: undefined,
              category: tagType,
              name: undefined,
            });
          }
        }
        await apiInstance.assignTagsToItems({
          companyId,
          assignTagDto,
        });
        // simple assign
      } else if (this.session_details) {
        // if training tag = tags[]
        if (Array.isArray(tags) && tags.length) {
          const assignTagDto: any[] = [];
          for (const tag of tags) {
            assignTagDto.push({
              itemId: this.session_details.id,
              itemType,
              tagId: tag.tagId,
              category: tagType,
              name: tag?.name,
            });
          }
          await apiInstance.assignTagsToItems({
            companyId,
            assignTagDto,
          });
          // if session tag = tags = {}
        } else if (!Array.isArray(tags)) {
          await apiInstance.assignTagsToItems({
            companyId,
            assignTagDto: [
              {
                itemId: this.session_details.id,
                itemType,
                tagId: tags?.tagId ?? undefined,
                category: tagType,
                name: tags?.name,
              },
            ],
          });
        } else {
          await apiInstance.assignTagsToItems({
            companyId,
            assignTagDto: [
              {
                itemId: this.session_details.id,
                itemType,
                tagId: undefined,
                category: tagType,
                name: undefined,
              },
            ],
          });
        }
      }
    },

    async editCompletionRate(listAttendees: TrainingPlanIndividual.ISessionDetailsAttendees[]) {
      const feedback = useFeedback();
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, BookingsApi);

      try {
        if (this.session_details_attendees?.length) {
          const array = listAttendees?.map((attendee) => {
            return {
              bookingId: attendee.original_id as string,
              attendedHours: attendee.attended_hours || 0,
            };
          });

          const data = await apiInstance.updateBookingCompletionRate({
            updateBookingsCompletionRateDto: {
              bookingsCompletionRate: array,
            },
          });

          if (data) {
            feedback.success("Vos modifications ont été sauvegardées", "small");
          }
        }
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },

    async batchAssignTrainingTags(
      selectAll: boolean,
      itemType: SdkItemType,
      tagIds: string[],
      tagCategory: TagCategoryEnum,
      itemIds?: string[],
    ) {
      const pagination = usePagination();
      const filters = useFilters();
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TrainingPlanApi);
      const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);
      const paginate_query = new URLSearchParams(paginate_options);
      const query_string = Object.fromEntries(paginate_query.entries());
      const filters_query = filters.getFiltersForSDK();
      try {
        await apiInstance.batchAssignTpTags({
          batchAssignTagsDto: {
            selectAll,
            tagIds,
            itemIds: itemIds ?? [],
            tagCategory,
            itemType,
          },
          ...query_string,
          ...filters_query,
        });
      } catch (error) {
        // feedback.error(`Une erreur est survenue`, "small");
      }
    },
  },
});
