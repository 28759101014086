import { defineStore } from "pinia";
import { useUsersHabilitationsStore } from "./users-habilitations.store";
import { useUsersHabilitationsApi } from "~~/composables/users-habilitations/useUsersHabilitationsApi";
import { useUsersHabilitationsValidation } from "~~/composables/users-habilitations/useUsersHabilitations";
import type { ICreateUserHabilitation } from "~~/utils/interfaces/users-interfaces";

export const useCreateUserHabilitationsStore = defineStore("useCreateUserHabilitationsStore", {
  state: (): ICreateUserHabilitation => {
    return {
      user: {
        user_id: null,
        habilitation_id: null,
        habilitation_name: null,
        duration_years: null,
        expiration_date: null,
        document_link: null,
        renewal_date: null,
        to_renew: true,
      },
      certificate: null,
      show_create_modal: false,
      user_error: null,
      duration_years_error: null,
      habilitation_error: null,
    };
  },
  getters: {},
  actions: {
    openCreateUserHabilitationsModal() {
      this.$reset();
      const uersHabilitationsStore = useUsersHabilitationsStore();
      uersHabilitationsStore.cleanValidationError();
      this.show_create_modal = true;
    },

    async validateUserHabilitationCreation() {
      const userHabilitationsValidation = useUsersHabilitationsValidation();
      const t$ = await userHabilitationsValidation.validateUsersHabilitationsCreation();
      if (t$.value.$errors) {
        this.user_error = t$.value.user_id.$silentErrors.map((m) => m.$message).join("");
        this.habilitation_error = t$.value.habilitation_name.$silentErrors.map((m) => m.$message).join("");
        this.duration_years_error = t$.value.habilitation_name.$silentErrors.map((m) => m.$message).join("");
      }
    },

    async createUserHabilitation() {
      const userHabilitationApi = useUsersHabilitationsApi();
      const { data } = await userHabilitationApi.createUserHabilitation({
        user_id: this.user.user_id,
        ...(this.user.habilitation_id && {
          habilitation_id: this.user.habilitation_id,
        }),
        habilitation_name: this.user.habilitation_name,
        duration_years: this.user.duration_years,
        expiration_date: this.user.expiration_date,
        renewal_date: this.user.renewal_date,
        to_renew: this.user.to_renew,
      });
      return data.value;
    },
  },
});
