import { type AsyncData } from "#app/composables/asyncData";
import { usePagination } from "../paginations/usePagination";
import { useFilters } from "../filters/useFilters";
import { useFetchApi } from "~/composables/api/useFetchApi";
import { TrainingStatus } from "~~/utils/enums/training.enums";
import { useHomeCatalogStore } from "~/store/catalog/home-catalog.store";

export const useCatalogApi = () => {
  async function fetchCompanyCatalog() {
    const pagination = usePagination();
    const filters = useFilters();
    const homeCatalogStore = useHomeCatalogStore();

    const paginate_options = pagination.getFindOptions({
      ...pagination.paginateOptions.value,
      category_id: homeCatalogStore.selectedCategory?.category_id,
    } as any);

    const paginate_query = new URLSearchParams(paginate_options).toString();
    const filters_query = filters.getFiltersPayload();

    const query_string = [paginate_query, filters_query].filter(Boolean).join("&");

    const default_params = `filters[training_status]=${TrainingStatus.LIVE}&filters[only_visible]=true`;

    return await useFetchApi("trainings", `/companyCatalog?${default_params}&${query_string}`, {
      method: "GET",
    });
  }

  async function fetchEmployeeCatalog({
    trainingId,
    isLive,
    limit,
    my_trainings,
  }: {
    trainingId?: string;
    isLive?: boolean;
    limit?: string | number;
    my_trainings?: boolean;
  }): Promise<AsyncData<any, any>> {
    const pagination = usePagination();
    const filters = useFilters();

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);

    const paginate_query = new URLSearchParams(paginate_options).toString();
    const filters_query = filters.getFiltersPayload();

    let query_string = [paginate_query, filters_query].filter(Boolean).join("&");

    query_string =
      query_string +
      (isLive ? `&filters[training_status]=${TrainingStatus.LIVE}` : "") +
      (my_trainings ? `&my_trainings=${my_trainings}` : "");

    if (trainingId || limit === "custom") {
      return await useFetchApi("trainings", `/companyCatalog`, {
        method: "GET",
        query: {
          training_id: trainingId,
          limit,
        },
      });
    }

    return await useFetchApi("trainings", `/companyCatalog?${query_string}`, {
      method: "GET",
    });
  }

  async function fetchAllEmployeeCatalog({
    trainingId,
    isLive,
    isVisible,
    limit,
  }: {
    trainingId?: string;
    isLive?: boolean;
    isVisible?: boolean;
    limit?: string;
  }): Promise<AsyncData<any, any>> {
    if (trainingId || limit === "custom") {
      return await useFetchApi("trainings", `/companyCatalog`, {
        method: "GET",
        query: {
          training_id: trainingId,
          limit: limit ?? 10,
        },
      });
    } else {
      let query_string = "limit=custom";

      query_string = query_string + (isLive ? `&filters[training_status]=${TrainingStatus.LIVE}` : "");

      query_string = query_string + (isVisible ? `&filters[only_visible]=true` : "");

      return await useFetchApi("trainings", `/companyCatalog?${query_string}`, {
        method: "GET",
      });
    }
  }

  async function changeVisibility(
    trainingIds: string[],
    isVisible: boolean,
    selectedAll = false,
  ): Promise<AsyncData<any, any>> {
    const pagination = usePagination();
    const filters = useFilters();

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);

    delete paginate_options.filter;

    const paginate_query = new URLSearchParams(paginate_options).toString();
    const filters_query = filters.getFiltersPayload();

    const query_string = [paginate_query, filters_query].filter(Boolean).join("&");

    return await useFetchApi("trainings", `/companyCatalog/change-visibility?${query_string}`, {
      method: "PATCH",
      body: {
        training_ids: trainingIds,
        visible: isVisible,
        selectedAll,
      },
    });
  }

  async function getCompanyTrainingNotExcluded(
    trainingIds: string[],
    selectedAll = false,
  ): Promise<AsyncData<any, any>> {
    const pagination = usePagination();

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);

    delete paginate_options.filter;

    return await useFetchApi("trainings", `/companyCatalog/company-training-not-excluded`, {
      method: "POST",
      body: {
        training_ids: trainingIds,
        selectedAll,
      },
    });
  }

  async function addTrainingToCatalog(
    selectAll: boolean,
    trainingIds: string[],
    excludedIds?: string[],
  ): Promise<AsyncData<any, any>> {
    const pagination = usePagination();
    const filters = useFilters();

    const filters_query = filters.getFiltersPayload();
    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);
    const paginate_query = new URLSearchParams(paginate_options).toString();
    const query_string = [paginate_query, filters_query].filter(Boolean).join("&");

    return await useFetchApi("trainings", `/companyCatalog/add-training-to-catalog?${query_string}`, {
      body: { selectedIds: trainingIds, excludedIds, selectAll },
      method: "PATCH",
    });
  }

  async function deleteTrainingFromCatalog(
    selectAll: boolean,
    trainingIds: string[],
    excludedIds?: string[],
  ): Promise<AsyncData<any, any>> {
    const pagination = usePagination();
    const filters = useFilters();

    const filters_query = filters.getFiltersPayload();
    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);
    const paginate_query = new URLSearchParams(paginate_options).toString();
    const query_string = [paginate_query, filters_query].filter(Boolean).join("&");

    return await useFetchApi("trainings", `/companyCatalog/delete-training-from-catalog?${query_string}`, {
      body: { selectedIds: trainingIds, excludedIds, selectAll },
      method: "PATCH",
    });
  }

  async function getCompanyTrainingDetails(trainingId: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("trainings", `/companyCatalog/${trainingId}`, {
      method: "GET",
    });
  }
  return {
    fetchCompanyCatalog,
    fetchAllEmployeeCatalog,
    fetchEmployeeCatalog,
    changeVisibility,
    getCompanyTrainingNotExcluded,
    addTrainingToCatalog,
    deleteTrainingFromCatalog,
    getCompanyTrainingDetails,
  };
};
