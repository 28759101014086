export enum SessionItemTypeEnum {
  INDIVIDUAL = "individual",
  GROUP = "group",
}

export enum SessionItemFormatEnum {
  ONLINE = "online",
  IN_PERSON = "in_person",
  MIXED = "mixed",
}

export enum SessionItemModalityEnum {
  SPECIFIC_DATES = "specific_dates",
  ON_DEMAND = "on_demand",
}
