import { type AsyncData } from "nuxt/app";
import { usePagination } from "../paginations/usePagination";
import { useFilters } from "../filters/useFilters";
import { useFetchApi } from "~/composables/api/useFetchApi";

export const useTrainingsApi = () => {
  async function createUpdate(body: Record<any, any>): Promise<AsyncData<any, any>> {
    return await useFetchApi("trainings", "/trainings", {
      method: "POST",
      body: { ...body },
    });
  }

  async function createOwnTraining(body: Record<any, any>): Promise<AsyncData<any, any>> {
    return await useFetchApi("trainings", "/trainings/own-partner/create-training", {
      method: "POST",
      body: { ...body },
    });
  }

  async function updateCreateTrainingAdmin(trainingId: string, adminIds: string[]): Promise<AsyncData<any, any>> {
    return await useFetchApi("trainings", `/trainingsAdmins/updateCreateAdmins/${trainingId}`, {
      method: "PATCH",
      body: { trainings_admins_ids: adminIds },
    });
  }

  async function fetchTrainings(): Promise<AsyncData<any, any>> {
    const pagination = usePagination();
    const filters = useFilters();

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);

    const paginate_query = new URLSearchParams(paginate_options).toString();

    const filters_query = filters.getFiltersPayload();
    const query_string = [paginate_query, filters_query].filter(Boolean).join("&");

    return await useFetchApi("trainings", `/trainings/simbel-trainings?${query_string}`, {
      method: "GET",
    });
  }

  async function fetchOwnPartnerTrainingDetails(trainingId: string) {
    return await useFetchApi("trainings", `/trainings/own-partner/training/${trainingId}`, {
      method: "GET",
    });
  }

  async function fetchTrainingsDetails(trainingId: string) {
    return await useFetchApi("trainings", `/trainings/details/${trainingId}`, {
      method: "GET",
    });
  }

  async function fetchTrainingPedagogicalReports(trainingId: string) {
    return await useFetchApi("trainings", `/pedagogicalReports/training/${trainingId}`, {
      method: "GET",
    });
  }

  return {
    createUpdate,
    fetchTrainings,
    fetchOwnPartnerTrainingDetails,
    createOwnTraining,
    fetchTrainingsDetails,
    fetchTrainingPedagogicalReports,
    updateCreateTrainingAdmin,
  };
};
