import { defineStore } from "pinia";
import { UserImportApi, type ImportsResponse, type UploadUsersCSVRequest } from "@simbelapp/auth-sdk";
import { useUsersApi } from "~/composables/users/useUsersApi";
import type { IUsersStore } from "~~/utils/interfaces/users-interfaces";
import { useFeedback } from "~~/composables/feedback/useFeedback";
import { useSDKApi } from "~/composables/api/useSDKApi";
import { MicroserviceEnum } from "~/utils/enums/common.enums";

export const useUsersStore = defineStore("users", {
  state: (): IUsersStore => {
    return {
      company_users: null,
      company_users_manager: null,
      company_users_group_admin: null,
      company_users_full_datas: null,
      user_details: null,
      all_users: null,
      user_groups: null,
      show_user_detail_modal: false,
      show_notify_users: false,
      show_csv_import_modal: false,
      show_disable_sso_modal: false,
      selected_users_id_list: [],
      excluded_users_id_list: [],
      show_csv_export_modal: false,
      show_end_user_modal: false,
    };
  },
  getters: {},
  actions: {
    /**
     * Fetches the company users from the API.
     *
     * @param enableRolesFilters - Flag indicating whether to get the list depending of the user role.
     * @returns {Promise<void>} - A promise that resolves when the company users are fetched.
     */
    async fetchCompanyUsers(enableRolesFilters = true) {
      const usersApi = useUsersApi();
      const data = await usersApi.fetchCompanyUsers(enableRolesFilters);

      if (Array.isArray(data)) {
        this.company_users = data;
      }
    },

    async fetchCompanyUsersLazyWithStore(enableRolesFilters = true, search?: string, usersIds?: string[]) {
      const usersApi = useUsersApi();
      const data = await usersApi.fetchCompanyUsers(enableRolesFilters, true, search, usersIds);

      if (!Array.isArray(data)) {
        this.company_users = data.data;
      } else if (data.length) {
        this.company_users = data;
      }
    },

    async fetchCompanyUsersManager() {
      const usersApi = useUsersApi();
      const { data } = await usersApi.fetchCompanyUsersManger();

      if (data.value) {
        this.company_users_manager = data.value;
      }
    },

    async fetchCompanyUsersGroupAdmin() {
      const usersApi = useUsersApi();
      const { data } = await usersApi.fetchCompanyUsersGroupAdmin();

      if (data.value) {
        this.company_users_group_admin = data.value;
      }
    },

    async fetchCompanyUsersFullDatas() {
      const usersApi = useUsersApi();
      const data = await usersApi.fetchCompanyUsersFullDatas();

      if (data) {
        this.company_users_full_datas = data;
      }
    },

    async fetchUserDetails(userId: string) {
      const feedback = useFeedback();
      const usersApi = useUsersApi();
      const router = useRouter();

      const { data } = await usersApi.fetchUserDetails(userId);

      if (data.value) {
        this.user_details = data.value;
        await this.fetchUserGroups(userId);
        await router.push({ query: { ...router.currentRoute.value.query, user_id: userId } });
      } else {
        feedback.error(`erreur`, "small");
      }
    },

    async fetchUserGroups(userId: string) {
      const feedback = useFeedback();
      const usersApi = useUsersApi();
      const { data } = await usersApi.fetchUserGroups(userId);
      if (data.value) {
        this.user_groups = data.value;
      } else {
        feedback.error(`erreur`, "small");
      }
    },

    async fetchNotExcludedUser(userIdList: string[], setValue?: boolean) {
      const feedback = useFeedback();
      const usersApi = useUsersApi();
      const { data } = await usersApi.fetchNotExcludedUser(userIdList);
      if (data.value) {
        this.selected_users_id_list = data.value;
        if (setValue) {
          this.company_users = data.value;
        }
      } else {
        feedback.error(`erreur`, "small");
      }
    },

    async importUsers(uploadUsersCSV: UploadUsersCSVRequest): Promise<ImportsResponse | null> {
      const feedback = useFeedback();
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, UserImportApi);
      const response: ImportsResponse | null = await apiInstance.uploadUsersCSV(uploadUsersCSV).catch(async (error) => {
        const errorResponse = await error.response.json();
        if (errorResponse.message.includes("Parallel import in progress")) {
          feedback.error("Un import est déjà en cours, veuillez attendre avant de relancer", "small");
          return null;
        } else {
          feedback.error("Erreur Interne", "small");
          return null;
        }
      });
      if (response) {
        return response;
      } else {
        return null;
      }
    },

    async findLastOneCSVImport(status?: string): Promise<ImportsResponse | null> {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, UserImportApi);
      const response: ImportsResponse | null = await apiInstance.getLastUsersImportState({ status });
      if (response) {
        return response;
      } else {
        return null;
      }
    },
    async notifyUsers(userIds: string[], message: string) {
      const feedback = useFeedback();
      const usersApi = useUsersApi();
      const { data } = await usersApi.notifyUsers({
        user_ids: userIds,
        message,
      });
      if (data.value) {
        return data;
      } else {
        feedback.error(`erreur`, "small");
      }
    },

    async fetchAdminAllUsers() {
      const feedback = useFeedback();
      const usersApi = useUsersApi();
      const { data } = await usersApi.fetchAdminAllUsers();

      if (data.value) {
        this.all_users = data.value;
      } else {
        feedback.error(`erreur`, "small");
      }
    },

    async disableSSO(
      disable: boolean,
      selectedIds: string[],
      selectAll: boolean,
      excludedIds?: string[],
    ): Promise<any> {
      const usersApi = useUsersApi();
      const feedback = useFeedback();

      const { data } = await usersApi.disableSSO(disable, selectAll, selectedIds, excludedIds);

      if (data.value) {
        return data;
      } else {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },
  },
});
