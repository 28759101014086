import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { storeToRefs } from "pinia";
import { QuizzDetailsTypeEnum } from "@simbelapp/trainings-sdk/models/QuizzDetails";
import { ActivityDetailsTypeEnum } from "@simbelapp/trainings-sdk";
import { useActivitiesStore } from "~/store/activities/activities.store";

export const useActivitiesValidation = () => {
  function validateActivity() {
    const activitiesStore = useActivitiesStore();
    const { activities } = storeToRefs(activitiesStore);

    const rules = computed(() => {
      return {
        type: {
          required: helpers.withMessage("Ce champ est obligatoire", required),
        },
      };
    });

    activities.value.map(async (activity) => {
      const si$ = useVuelidate(rules, activity);
      await si$.value.$validate();
      activitiesStore.pushValidationErrors(si$.value.$errors, activity.activityId);
    });
  }

  function validateEmptyActivityQuiz() {
    const activitiesStore = useActivitiesStore();
    const { activities } = storeToRefs(activitiesStore);

    const rules = computed(() => {
      return {
        quizType: {
          required: helpers.withMessage("Ce champ est obligatoire", required),
        },
      };
    });

    activities.value.map(async (activity) => {
      if (activity.type !== ActivityDetailsTypeEnum.Quiz) return;
      const si$ = useVuelidate(rules, activity || []);
      await si$.value.$validate();
      activitiesStore.pushValidationErrors(si$.value.$errors, activity.activityId);
    });
  }

  function validateActivityQuiz(quizId: string) {
    const activitiesStore = useActivitiesStore();
    const { currentActivity, quizzesErrors } = storeToRefs(activitiesStore);

    const hasRightAnswer = (value: any) => {
      return value.find((answer: any) => answer.isRightAnswer === true);
    };

    const rules = computed(() => {
      return {
        quizAnswers: {
          hasRightAnswer: helpers.withMessage("Au moins une réponse doit être correcte", hasRightAnswer),
        },
      };
    });

    // Remove from errors the quizId
    quizzesErrors.value = quizzesErrors.value.filter((error) => error?.quizId !== quizId);

    currentActivity.value?.quizzesDetails?.map(async (quizDetail) => {
      if (
        quizDetail.type === QuizzDetailsTypeEnum.MultipleChoiceQuestion &&
        quizDetail.quizId === quizId &&
        currentActivity.value?.activityId
      ) {
        const quizAnswersRef = ref(quizDetail.quizAnswers);
        const si$ = useVuelidate(rules, { quizAnswers: quizAnswersRef });
        await si$.value.$validate();
        activitiesStore.pushValidationQuizzesErrors(
          si$.value.$errors,
          currentActivity.value?.activityId,
          quizDetail.quizId,
        );
      }
    });
  }

  return {
    validateActivity,
    validateActivityQuiz,
    validateEmptyActivityQuiz,
  };
};
