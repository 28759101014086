import { defineStore } from "pinia";
import {
  AdminBookingsApi,
  BookingsApi,
  type BookingNotificationsSettingsDto,
  type RegulateBookings200Response,
  type RegulateBookingsDto,
} from "@simbelapp/order-sdk";
import { useSessionsStore } from "../sessions/sessions-store";
import { useTrainingsStore } from "../trainings/trainings.store";
import { useUsersHabilitationsStore } from "../users-habilitations/users-habilitations.store";
import { useBookingsApi } from "~~/composables/bookings/useBookingsApi";
import { useFeedback } from "~/composables/feedback/useFeedback";
import type {
  ICreateBookingPayload,
  IEndOfTrainingForUsersList,
  IUpdateBookingSessionPayload,
} from "~~/utils/interfaces/bookings-interfaces";
import { useEmployeeTrainingsStore } from "~/store/employee-trainings/employee-trainings.store";
import { useSDKApi } from "~/composables/api/useSDKApi";
import { MicroserviceEnum } from "~/utils/enums/common.enums";

export const useBookingsStore = defineStore("bookingsStore", {
  getters: {},
  actions: {
    async updateBookingSession(updateBookingSessionPayload: IUpdateBookingSessionPayload): Promise<any> {
      const bookingsApi = useBookingsApi();
      const feedback = useFeedback();

      try {
        await bookingsApi.updateBookingSession(updateBookingSessionPayload);
        feedback.success("Vos modifications ont bien été sauvegardées", "small");
      } catch (error) {
        feedback.error("Une erreur est survenue", "small");
      }
    },
    async updateBooking(booking_id: string, is_not_billable: boolean): Promise<any> {
      const feedback = useFeedback();
      try {
        const bookingsApi = useBookingsApi();

        const { data } = await bookingsApi.updateBooking(booking_id, {
          is_not_billable,
        });
        return data;
      } catch (error) {
        feedback.error("Une erreur est survenue", "small");
      }
    },
    async regulateBooking(payload: RegulateBookingsDto): Promise<RegulateBookings200Response | undefined> {
      const feedback = useFeedback();

      try {
        const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, AdminBookingsApi);

        const data = await apiInstance.regulateBookings({
          regulateBookingsDto: payload,
        });

        return data;
      } catch (error) {
        feedback.error("Une erreur est survenue", "small");
        return undefined;
      }
    },

    async cancelBooking(bookingId: string, doNotNotify: boolean, requestId?: string): Promise<any> {
      const bookingsApi = useBookingsApi();
      const data = await bookingsApi.cancelBooking(bookingId, doNotNotify, requestId);
      return data;
    },

    async refuseBooking(bookingId: string): Promise<any> {
      const bookingsApi = useBookingsApi();
      const data = await bookingsApi.refuseBooking(bookingId);
      return data;
    },
    async createBookings(createBookings: ICreateBookingPayload, isAdminCreateBooking = false): Promise<any> {
      try {
        createBookings.is_admin_create_booking = isAdminCreateBooking;
        const bookingsApi = useBookingsApi();
        const data = await bookingsApi.createBooking(createBookings);
        return data;
      } catch (error) {
        const feedback = useFeedback();
        feedback.error("Une erreur est survenue", "small");
      }
    },

    async updateBookingNotificationsSettings(
      bookingId: string,
      bookingNotificationsSettingsDto: BookingNotificationsSettingsDto,
    ) {
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, BookingsApi);
      await apiInstance.updateBookingNotificationsSettings({
        bookingId,
        bookingNotificationsSettingsDto,
      });
    },

    async setEndOfTrainingForUsersList(endOfTrainingForUsersList: IEndOfTrainingForUsersList) {
      const bookingsApi = useBookingsApi();
      const { data } = await bookingsApi.setEndOfTrainingForUsersList(endOfTrainingForUsersList);
      return data;
    },
    async setEndOfTraining({
      training_validated,
      certificate,
      manual_duration,
      no_show_date,
      no_show_reason,
      no_show_desc,
    }: {
      training_validated?: boolean;
      certificate?: File;
      manual_duration?: number;
      no_show_date?: Date;
      no_show_reason?: string | null;
      no_show_desc?: string;
    }): Promise<any> {
      const { training_plan_session_individual, fetchTrainingPlanSession } = useSessionsStore();
      const trainingStore = useTrainingsStore();
      const bookingsApi = useBookingsApi();
      const usersHabilitationsStore = useUsersHabilitationsStore();
      const feedback = useFeedback();
      if (training_plan_session_individual?.training_id) {
        await trainingStore.fetchOwnPartnerTrainingDetails(training_plan_session_individual?.training_id);
      }
      const isDurationComplete =
        training_plan_session_individual?.attended_hours === training_plan_session_individual?.training_duration;

      if (
        training_plan_session_individual?.session_id &&
        training_plan_session_individual?.booking_id &&
        training_plan_session_individual?.start_date
      ) {
        const { training_details } = useTrainingsStore();
        const { data } = await bookingsApi.setEnfOfTraining({
          session_id: training_plan_session_individual?.session_id,
          booking_id: training_plan_session_individual?.booking_id,
          end_date: training_plan_session_individual?.end_date,
          origin_id: training_plan_session_individual?.request_id || null,
          manual_duration:
            manual_duration !== undefined
              ? manual_duration
              : !isDurationComplete
                ? training_plan_session_individual?.attended_hours
                : undefined,
          start_date: training_plan_session_individual?.start_date,
          no_show_date: no_show_date || undefined,
          no_show_reason: no_show_reason || undefined,
          no_show_desc: no_show_desc || undefined,
          ...(training_details?.habilitation?.habilitation_id && {
            habilitation_id: training_details?.habilitation?.habilitation_id,
            training_validated,
            expiration_date: training_details?.habilitation?.expiration_date
              ? new Date(training_details?.habilitation?.expiration_date)
              : undefined,
            duration_years: training_details?.habilitation?.duration_years,
          }),
        });
        if (data.value) {
          const id = training_plan_session_individual?.id || "";
          await fetchTrainingPlanSession(id);
          const hab_id: string =
            data.value.userHabilitation?.habilitation_id ?? data.value.userHabilitation?.user_habilitation_id;
          if (data.value.userHabilitation && hab_id && certificate) {
            const dataCertificate = await usersHabilitationsStore.uploadCertificate(hab_id, certificate);

            if (dataCertificate) {
              feedback.greeting("Le certificat a bien été sauvegardé", "small");
            } else {
              feedback.error(`Une erreur est survenue lors de l'ajout du certificat`, "small");
            }
          }
          feedback.greeting("Vos modifications ont bien été sauvegardées", "small");
          return data.value;
        } else {
          feedback.error("Une erreur est survenue", "small");
        }
      }
    },

    async editEndOfTraining({
      no_show_date,
      no_show_reason,
      no_show_desc,
    }: {
      no_show_date?: Date;
      no_show_reason?: string | null;
      no_show_desc?: string;
    }): Promise<any> {
      const { training_plan_session_individual, fetchTrainingPlanSession } = useSessionsStore();
      const bookingsApi = useBookingsApi();
      const feedback = useFeedback();
      if (training_plan_session_individual?.booking_id) {
        const { data } = await bookingsApi.editEnfOfTraining(
          {
            no_show_date,
            no_show_reason: no_show_reason || null,
            no_show_desc: no_show_desc === "" ? null : no_show_desc,
          },
          training_plan_session_individual?.booking_id,
        );
        if (data.value) {
          const id = training_plan_session_individual?.id || "";
          await fetchTrainingPlanSession(id);

          feedback.greeting("Vos modifications ont bien été sauvegardées", "small");
          return data.value;
        } else {
          feedback.error("Une erreur est survenue", "small");
        }
      }
    },

    async setEndOfTrainingLMS(bookingId: string, sessionId: string, originId?: string | null): Promise<void> {
      const employeeTrainingsStore = useEmployeeTrainingsStore();
      const bookingsApi = useBookingsApi();
      const feedback = useFeedback();

      if (bookingId && sessionId) {
        const { data } = await bookingsApi.setEnfOfTraining({
          session_id: sessionId,
          booking_id: bookingId,
          end_date: new Date(),
          origin_id: originId || null,
        });
        if (data.value) {
          await employeeTrainingsStore.fetchEmployeeTraining(bookingId);
        } else {
          feedback.error("Une erreur est survenue", "small");
        }
      }
    },
  },
});
