import { type AsyncData } from "#app/composables/asyncData";
import { useFetchApi } from "~/composables/api/useFetchApi";
import type { ICategory } from "~~/utils/interfaces/categories-interfaces";

export const useCategoriesApi = () => {
  async function fetchCategories(): Promise<AsyncData<any, any>> {
    return await useFetchApi("trainings", `/categories/companyCategories`, {
      method: "GET",
    });
  }

  async function fetchCatalogCategories(): Promise<AsyncData<any, any>> {
    return await useFetchApi("trainings", `/categories/catalogCategories`, {
      method: "GET",
    });
  }

  async function fetchAllCategories(): Promise<AsyncData<any, any>> {
    return await useFetchApi("trainings", `/categories`, {
      method: "GET",
    });
  }

  async function createUpdateCategories(body: ICategory[]): Promise<AsyncData<any, any>> {
    return await useFetchApi("trainings", `/categories`, {
      method: "PATCH",
      body: { categories: [...body] },
    });
  }

  return {
    fetchAllCategories,
    fetchCategories,
    createUpdateCategories,
    fetchCatalogCategories,
  };
};
