import useVuelidate from "@vuelidate/core";
import { email, helpers, minValue, required, requiredIf, url } from "@vuelidate/validators";
import { storeToRefs } from "pinia";
import { SettingScopesEnum } from "@simbelapp/auth-sdk";
import { useFeedback } from "../feedback/useFeedback";
import { usePagination } from "../paginations/usePagination";
import { useCatalogStore } from "~~/store/catalog/catalog.store";
import { useCreateTrainingStore } from "~~/store/trainings/create-training.store";
import { useTrainingsStore } from "~~/store/trainings/trainings.store";
import type { IFooterActions } from "~~/utils/interfaces/common-interfaces";
import type { IHeaderNav, IIndexHeader } from "~~/utils/interfaces/table-interfaces";
import { useCreateBuilderTrainingStore } from "~/store/trainings/create-training-builder.store";
import { TrainingTypeEnum } from "~/utils/enums/training.enums";

export const useTrainings = () => {
  const headers = useState<IIndexHeader[]>(() => [
    {
      name: "Formation",
      sort: true,
      key: "title",
      minWidthPx: 287,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.SimbelCatalogTab,
    },
    {
      name: "Prestataire",
      sort: true,
      key: "training_partner_name",
      minWidthPx: 164,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.SimbelCatalogTab,
    },
    {
      name: "Catégorie",
      sort: true,
      key: "categories",
      minWidthPx: 210,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.SimbelCatalogTab,
    },
    {
      name: "Type",
      sort: true,
      key: "session_items_types",
      minWidthPx: 93,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.SimbelCatalogTab,
    },
    {
      name: "Prix (HT)",
      sort: true,
      key: "default_price",
      minWidthPx: 124,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.SimbelCatalogTab,
    },
    {
      name: "Format",
      sort: true,
      key: "session_items_formats",
      minWidthPx: 156,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.SimbelCatalogTab,
    },
    {
      name: "Durée",
      sort: true,
      key: "duration_hours",
      minWidthPx: 81,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.SimbelCatalogTab,
    },
    {
      name: "",
      sort: false,
      key: "actions",
      minWidthPx: 80,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.SimbelCatalogTab,
    },
    {
      name: "Référent",
      sort: true,
      key: "training_admin",
      minWidthPx: 140,
      maxWidthFr: 1,
      visible: false,
      scope: SettingScopesEnum.SimbelCatalogTab,
    },
  ]);

  const footersActionsSimbel: IFooterActions[] = [
    {
      name: "Ajouter à mon répertoire",
      logoName: "add-library",
      action: addTrainingsToCatalog,
    },
  ];
  function addTrainingTag() {
    showEditTagModal.value = true;
  }
  const footersActionsCatalog: IFooterActions[] = [
    {
      name: "Tag formation",
      logoName: "categorie",
      action: addTrainingTag,
    },
    {
      name: "Masquer",
      logoName: "hide",
      action: hideMultiVisibility,
    },
    {
      name: "Rendre visible",
      logoName: "see",
      action: showMultiVisibility,
    },
    {
      name: "Archiver",
      logoName: "delete_trash",
      action: deleteTrainingsFromCatalog,
    },
  ];

  const catalogStore = useCatalogStore();
  const { showEditTagModal } = storeToRefs(catalogStore);
  async function hideMultiVisibility() {
    const feedback = useFeedback();
    const { companyCatalog, selectedItems, selectedAll, excludedItems } = catalogStore;
    let isSuccess;
    let excludedIds: string[] = [];

    if (companyCatalog?.data) {
      if (selectedItems.length > 0 && !selectedAll) {
        const selectedIds = selectedItems.map((training) => training.training_id);
        isSuccess = await catalogStore.changeVisibility(selectedIds, false);

        updateVisibility();
      } else if (selectedAll) {
        if (excludedItems.length > 0) {
          excludedIds = excludedItems.map((training) => training.training_id);
          isSuccess = await catalogStore.changeVisibility(excludedIds, false, selectedAll);
        } else {
          isSuccess = await catalogStore.changeVisibility([], false, selectedAll);
        }

        updateVisibility();
      }
    }

    if (isSuccess) {
      feedback.success("Vos modifications ont bien été sauvegardées", "small");
    }
  }

  async function showMultiVisibility() {
    const feedback = useFeedback();
    const { companyCatalog, selectedItems, selectedAll, excludedItems } = catalogStore;
    let isSuccess;
    let excludedIds: string[] = [];

    if (companyCatalog?.data) {
      if (selectedItems.length > 0 && !selectedAll) {
        const selectedIds = selectedItems.map((training) => training.training_id);
        isSuccess = await catalogStore.changeVisibility(selectedIds, true);

        updateVisibility();
      } else if (selectedAll) {
        if (excludedItems.length > 0) {
          excludedIds = excludedItems.map((training) => training.training_id);
          isSuccess = await catalogStore.changeVisibility(excludedIds, true, selectedAll);
        } else {
          isSuccess = await catalogStore.changeVisibility([], true, selectedAll);
        }

        updateVisibility();
      }
    }

    if (isSuccess) {
      feedback.success("Vos modifications ont bien été sauvegardées", "small");
    }
  }

  function deleteTrainingsFromCatalog() {
    const catalogsStore = useCatalogStore();

    catalogsStore.showDeleteCatalog = true;
  }

  async function updateVisibility() {
    const paginate = usePagination();

    catalogStore.selectedItems = [];
    catalogStore.selectedAll = false;
    catalogStore.excludedItems = [];
    await paginate.fetchData();
  }

  function addTrainingsToCatalog() {
    const trainingsStore = useTrainingsStore();

    trainingsStore.showAddCatalog = true;
  }

  function getNav(is_edit?: boolean): IHeaderNav[] {
    const nav = [
      { name: "Général", nb: 0 },
      { name: "Contenu", nb: 1 },
      { name: "Inscriptions", nb: 2 },
      { name: "Modalités", nb: 3 },
    ];
    if (is_edit) {
      nav.push({
        name: "Sessions",
        nb: 4,
      });
    }

    return nav;
  }

  function getBuilderNav(is_edit?: boolean): IHeaderNav[] {
    const nav = [
      { name: "Contenu", nb: 0 },
      { name: "Modalités", nb: 1 },
      { name: "Informations avancées", nb: 2 },
    ];
    if (is_edit) {
      nav.push({
        name: "Sessions",
        nb: 3,
      });
    }

    return nav;
  }

  function trainingTypeMap(value: string) {
    return [
      {
        name: "external_training",
        value: "Externe",
        type: "grey",
        icon: "graduation_cap_on",
      },
      {
        name: "internal_training",
        value: "Interne",
        type: "grey",
        icon: "graduation_cap_on_v2",
      },
    ].find((x) => x.name === value);
  }

  return {
    headers,
    footersActionsSimbel,
    footersActionsCatalog,
    getNav,
    getBuilderNav,
    trainingTypeMap,
  };
};

export const useTrainingValidation = () => {
  async function validateStepSessionItem() {
    const createTrainingStore = useCreateTrainingStore();
    const { training } = storeToRefs(createTrainingStore);

    function somethingIsSelected(_: any, siblings: any) {
      return siblings.session_items.individual.isChecked || siblings.session_items.group.isChecked;
    }

    const rules = computed(() => {
      return {
        session_items: {
          ...(training.value.session_items?.group.isChecked &&
            training.value.general.type === TrainingTypeEnum.EXTERNAL && {
              group: {
                format: {
                  required: helpers.withMessage("Vous devez sélectionner un format", required),
                },
                modality: {},
                price_excl_tax: {
                  required: helpers.withMessage("Ce champ est obligatoire", required),
                },
                type: { required },
                max_nb_attendees: {
                  required: helpers.withMessage("Veuillez saisir au moins 1", required),
                  ...(training.value.session_items.group.min_nb_attendees && {
                    minValue: helpers.withMessage(
                      `La valeur ne doit pas être inférieur à ${training.value.session_items.group.min_nb_attendees}`,
                      minValue(training.value.session_items.group.min_nb_attendees),
                    ),
                  }),
                  minValue: helpers.withMessage(`Veuillez saisir au moins 1`, minValue(1)),
                },
                isChecked: { required },
              },
            }),
          ...(training.value.session_items?.individual.isChecked && {
            individual: {
              format: {
                required: helpers.withMessage("Vous devez sélectionner un format", required),
              },
              modality: {},
              price_excl_tax: {
                required: helpers.withMessage("Ce champ est obligatoire", required),
              },
              type: { required },
              isChecked: { required },
            },
          }),
          required: helpers.withMessage("Vous devez sélectionner un type d’inscription.", somethingIsSelected),
        },
      };
    });

    const si$ = useVuelidate(rules, training.value);
    await si$.value.$validate();
    createTrainingStore.pushValidationErrors(si$.value.$errors);
  }

  async function validateStepGeneral() {
    const createTrainingStore = useCreateTrainingStore();
    const { training } = storeToRefs(createTrainingStore);

    const rules = computed(() => {
      return {
        general: {
          training_partner: {
            required: helpers.withMessage("Ce champ est obligatoire", required),
          },
          title: {
            required: helpers.withMessage("Ce champ est obligatoire", required),
          },
          main_contact_email: {
            email: helpers.withMessage("Email invalide", email),
          },
          ...(training.value.general.type === TrainingTypeEnum.EXTERNAL && {
            external_url: {
              required: helpers.withMessage("Lien invalide", url),
            },
          }),
          ...(training.value.general.type === TrainingTypeEnum.INTERNAL && {
            internal_url: {
              required: helpers.withMessage("Lien invalide", url),
            },
          }),
          categories: {
            required: helpers.withMessage("Ce champ est obligatoire", required),
          },
          duration_hours: {
            required: helpers.withMessage("Ce champ est obligatoire", required),
          },
          duration_days: {
            required: helpers.withMessage("Ce champ est obligatoire", required),
          },
          visibility: {
            required: helpers.withMessage("Ce champ est obligatoire", required),
          },
        },
      };
    });
    const si$ = useVuelidate(rules, training.value);
    await si$.value.$validate();

    createTrainingStore.pushValidationErrors(si$.value.$errors);
  }

  async function validateStepContent() {
    const createTrainingStore = useCreateTrainingStore();
    const { training } = storeToRefs(createTrainingStore);

    const rules = computed(() => {
      return {
        content: {
          habilitation: {
            required: helpers.withMessage(
              "Ce champ est obligatoire",
              requiredIf(training.value.content.isHabilitation),
            ),
          },
          duration_years: {
            required: helpers.withMessage(
              "Ce champ est obligatoire",
              requiredIf(training.value.content.isHabilitation),
            ),
          },
        },
      };
    });
    const si$ = useVuelidate(rules, training.value);
    await si$.value.$validate();

    createTrainingStore.pushValidationErrors(si$.value.$errors);
  }

  async function validateStepSession() {
    const createTrainingStore = useCreateTrainingStore();
    const { training } = storeToRefs(createTrainingStore);

    const rules = computed(() => {
      return {
        sessions: {
          session_draft: {
            sessionItemType: {
              required: helpers.withMessage(
                "Vous devez sélectionner un type d’inscription.",
                requiredIf(training.value.sessions.show_add_session as boolean),
              ),
            },
            // startDate: {
            //   required: helpers.withMessage(
            //     "Ce champ est obligatoire",
            //     requiredIf(training.value.sessions.session_draft?.sessionItemType as string),
            //   ),
            // },
            // endDate: {
            //   required: helpers.withMessage(
            //     "Ce champ est obligatoire",
            //     requiredIf(training.value.sessions.session_draft?.sessionItemType as string),
            //   ),
            // },
          },
        },
      };
    });
    const si$ = useVuelidate(rules, training.value);
    await si$.value.$validate();

    createTrainingStore.pushValidationErrors(si$.value.$errors);
  }

  return {
    validateStepSessionItem,
    validateStepGeneral,
    validateStepContent,
    validateStepSession,
  };
};

export const useTrainingBuilderValidation = () => {
  async function validateStepContent() {
    const createBuilderTrainingStore = useCreateBuilderTrainingStore();
    const { training } = storeToRefs(createBuilderTrainingStore);

    const rules = computed(() => {
      return {
        content: {
          trainingPartner: {
            required: helpers.withMessage("Ce champ est obligatoire", required),
          },
          title: {
            required: helpers.withMessage("Ce champ est obligatoire", required),
          },
        },
      };
    });
    const si$ = useVuelidate(rules, training.value);
    await si$.value.$validate();

    createBuilderTrainingStore.pushValidationErrors(si$.value.$errors);
  }
  async function validateStepContentFull() {
    const createBuilderTrainingStore = useCreateBuilderTrainingStore();
    const { training } = storeToRefs(createBuilderTrainingStore);

    const rules = computed(() => {
      return {
        content: {
          trainingPartner: {
            required: helpers.withMessage("Ce champ est obligatoire", required),
          },
          title: {
            required: helpers.withMessage("Ce champ est obligatoire", required),
          },
          language: {
            required: helpers.withMessage("Ce champ est obligatoire", required),
          },
        },
      };
    });
    const si$ = useVuelidate(rules, training.value);
    await si$.value.$validate();

    createBuilderTrainingStore.pushValidationErrors(si$.value.$errors);
  }
  async function validateStepModality() {
    const createBuilderTrainingStore = useCreateBuilderTrainingStore();
    const { training } = storeToRefs(createBuilderTrainingStore);

    const rules = computed(() => {
      return {
        modalities: {
          durationDays: {
            required: helpers.withMessage("Ce champ est obligatoire", required),
          },
        },
      };
    });
    const si$ = useVuelidate(rules, training.value);
    await si$.value.$validate();

    createBuilderTrainingStore.pushValidationErrors(si$.value.$errors);
  }
  async function validateStepSession() {
    const createBuilderTrainingStore = useCreateBuilderTrainingStore();
    const { training } = storeToRefs(createBuilderTrainingStore);

    const rules = computed(() => {
      return {
        sessions: {
          session_draft: {
            startDate: {
              required: helpers.withMessage(
                "Ce champ est obligatoire",
                requiredIf(training.value.sessions.show_add_session as boolean),
              ),
            },
            endDate: {
              required: helpers.withMessage(
                "Ce champ est obligatoire",
                requiredIf(training.value.sessions.show_add_session as boolean),
              ),
            },
          },
        },
      };
    });
    const si$ = useVuelidate(rules, training.value);
    await si$.value.$validate();

    createBuilderTrainingStore.pushValidationErrors(si$.value.$errors);
  }
  async function validateStepAdvanced() {
    const createBuilderTrainingStore = useCreateBuilderTrainingStore();
    const { training } = storeToRefs(createBuilderTrainingStore);

    const rules = computed(() => {
      return {
        advanced: {
          categories: {
            required: helpers.withMessage("Ce champ est obligatoire", required),
          },
        },
      };
    });
    const si$ = useVuelidate(rules, training.value);
    await si$.value.$validate();

    createBuilderTrainingStore.pushValidationErrors(si$.value.$errors);
  }

  async function validateStepContentForOneElement(element: string, tab: string) {
    const createBuilderTrainingStore = useCreateBuilderTrainingStore();
    const { training } = storeToRefs(createBuilderTrainingStore);

    const rules = computed(() => {
      return {
        [tab]: {
          [element]: {
            required: helpers.withMessage("Ce champ est obligatoire", required),
          },
        },
      };
    });
    const si$ = useVuelidate(rules, training.value);
    await si$.value.$validate();

    createBuilderTrainingStore.pushValidationErrors(si$.value.$errors);

    if (!si$.value.$errors.find((err) => err.$property === element)) {
      delete training.value.errors[`${tab}.${element}`];
    }
  }

  return {
    validateStepContentForOneElement,
    validateStepModality,
    validateStepContent,
    validateStepContentFull,
    validateStepSession,
    validateStepAdvanced,
  };
};
